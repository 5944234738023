import {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import queryString from 'query-string';

import {ERoomSchemaNames} from 'types/venue';
import {setBookingPreview} from 'store/venues/actions';
import {
  cleanCustomerSelectedPackages,
  setCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import {ERoomType} from 'types/dto/ERoomType.type';
import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';
import {useQuery} from 'hooks/useQuery';
import {makeInitPreviewUnitsData} from '../helpers';
import {useRedirectResult} from 'view/components/AdyenPayment/hooks/useRedirectResult';
import {useAppSelector} from 'store/hooks';
import {useShortList} from 'view/venue/Offer/hooks/useShortList';
import LocalStorageService from 'infra/common/localStorage.service';
import {setIsRegistrationAction} from 'store/app/appSlice';
import {useMultiDayVenueDetailsData} from '../../NW2VenueDetails/useMultiDayVenueDetails';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {IUnitResponse} from 'types/dto/IPublicVenue';
import useParamsVenueId from 'view/venue/hooks/useParamsVenueId';
import {useSelectedRoomId} from '../../hooks/useSelectedRoomId';

interface IProps {
  id: number | string;
  isOfferRequest?: boolean; //TODO: maybe cut out
  isMeetingRoom?: boolean;
}

export function useBookingInitialData({
  id,
  isOfferRequest,
  isMeetingRoom,
}: IProps) {
  const navigate = useNavigate();
  const {shortList} = useShortList();
  const dispatch = useDispatch();
  const {paramsVenueIds} = useParamsVenueId();

  const customerSelectedPackages = useAppSelector(
    ({customer}) => customer.customerSelectedPackages,
  );

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const filterData = useAppSelector(({search}) => search.multiSearchPayload);

  const details = useAppSelector(({venueDetails}) => venueDetails.details);

  const days = details?.days;
  const extras = details?.extras;

  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`, []),
  );

  const venueId = String(paramsVenueIds[0] ? paramsVenueIds : id);

  useMultiDayVenueDetailsData(venueId, isOfferRequest);
  const {venueUnits: unitsToBook} = useVenueDetailsData<IUnitResponse>();

  const {startDate: searchStartDate, endDate: searchEndDate} = searchCriteria;

  const {
    seatingPlan = '',
    roomType = '',
    // unitIds = '',
    isMultiRequest: isQueryMultiRequest = false,
  } = useQuery();

  const stringSearchData = queryString.stringify({...searchCriteria});
  const onGoToVenueDetails = useCallback(() => {
    if (shortList.items.length && typeof isQueryMultiRequest === 'boolean') {
      navigate(-1);
      return;
    }

    navigate({
      pathname: `/venue/${id}/details`,
      search: stringSearchData,
    });
  }, [
    id,
    isQueryMultiRequest,
    navigate,
    shortList.items.length,
    stringSearchData,
  ]);

  const [getSelectedVenueUnitId] = useSelectedRoomId();

  const venueUnits = useMemo(() => {
    // if (isMeetingRoom) {

    return (unitsToBook as IUnitResponse[])?.filter(
      ({isOptimalPrice, unitId, checkIn}) => {
        const selectedVenueUnitId = getSelectedVenueUnitId({
          venueId: Number(id),
          checkIn,
        });

        if (selectedVenueUnitId) {
          return unitId === selectedVenueUnitId;
        }

        return isOptimalPrice;
      },
    );
    // }

    // return unitsToBook?.filter((unit) => {
    //   return unitIds
    //     ? typeof unitIds === 'string' && unitIds?.includes(String(unit.unitId))
    //     : unit;
    // });
  }, [getSelectedVenueUnitId, id, unitsToBook]);

  const isRfp = venueUnits?.some(({isRtb}) => !isRtb);

  // clear store data on page leave
  useEffect(() => {
    return () => {
      dispatch(
        setBookingPreview({
          bookingMaskPreviewUnits: [],
          attendees: [],
        }),
      );

      dispatch(cleanCustomerSelectedPackages());
      dispatch(setIsRegistrationAction(false));
    };
  }, [dispatch]);

  const {isRedirectResult} = useRedirectResult();
  const isInitPreviewUnitsExist = !!previewUnits.length;

  const guestBookingPreviewData =
    LocalStorageService.getByKey('bookingPreviewData');

  const parsedPreviewUnits = JSON.parse(guestBookingPreviewData as string);

  const guestPreviewUnits = parsedPreviewUnits
    ? parsedPreviewUnits?.previewUnits
    : null;

  // Setting array of default package values, initially customer has all packages turned off,
  // it will be [day:[[unitId: null]...]...] for multiDay
  useEffect(() => {
    if (!customerSelectedPackages && previewUnits.length) {
      const defaultMultiDayCustomerPackages = previewUnits.reduce(
        (prev, curr) => {
          const unitsMapByDay = curr.units.map(({unitId}) => [unitId, null]);
          return [...prev, unitsMapByDay];
        },
        [] as any,
      );

      dispatch(setCustomerSelectedPackages(defaultMultiDayCustomerPackages));
    }
  }, [dispatch, previewUnits, customerSelectedPackages]);

  useEffect(() => {
    if (
      isRedirectResult ||
      isInitPreviewUnitsExist ||
      !venueUnits?.length ||
      !isMeetingRoom
    )
      return;

    const initPreviewUnitsData = makeInitPreviewUnitsData({
      searchStartDate,
      searchEndDate,
      venueUnits,
      days,
      seatingPlan: seatingPlan as ERoomSchemaNames,
      roomType: roomType as ERoomType,
      extras,
      participants: 0,
      searchVenuesData: filterData,
    });

    dispatch(
      setBookingPreview({
        bookingMaskPreviewUnits: guestPreviewUnits?.length
          ? guestPreviewUnits
          : initPreviewUnitsData,
      }),
    );
  }, [
    days,
    dispatch,
    extras,
    filterData,
    guestBookingPreviewData,
    guestPreviewUnits,
    isInitPreviewUnitsExist,
    isMeetingRoom,
    isRedirectResult,
    roomType,
    searchEndDate,
    searchStartDate,
    seatingPlan,
    venueUnits,
  ]);

  const formInitialValues = {
    ...(isOfferRequest ? {optionDate: ''} : {}),
  };

  return {
    isRfp,
    formInitialValues,
    onGoToVenueDetails,
  };
}
