import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon/Icon';
import useSearchData from 'view/venue/hooks/useSearchData';

import {TextValue} from 'view/components/TextValue';
import {getCurrencySymbol} from 'utils/stringUtils';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {EAccommodationType, EVenueType} from 'types/dto/IPublicVenue';
import {useClickOutside} from 'hooks/useClickOutside';

import {
  AmenitiesRowContainer,
  BookInstantlyContainer,
  ButtonContainer,
  Card,
  ContentBlock,
  ImageContainer,
  Row,
  TotalPriceText,
  VenueName,
} from './NW2VenuesListMapItem.styles';
import Image from 'view/components/Image';
import {getIcon} from 'view/venue/helpers/spaces';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IMergedVenue} from 'types/search';
import {useIsRequestForOfferBtnVisible} from 'view/venue/hooks/useIsRequestForOfferBtnVisible';
import {EAspectRation} from 'view/components/Image/Image';

type TProps = {
  venue: IMergedVenue;
  closeActiveMarker: () => void;
  onRequestForOfferClick: (venue: IMergedVenue) => () => void;
  checkIsShortListItemAdded: (venueId: number) => boolean;
  roomType: ERoomType;
  hasPrice: boolean;
};

const NW2VenuesListMapItem = ({
  venue,
  closeActiveMarker,
  hasPrice,
  roomType,
  checkIsShortListItemAdded,
  onRequestForOfferClick,
}: TProps) => {
  const {
    currency,
    coverImageLink,
    name,
    accommodationId,
    totalPrice,
    accommodationType,
    type,
  } = venue;

  const isShortlisted = checkIsShortListItemAdded?.(accommodationId);

  const navigate = useNavigate();

  const [element] = useClickOutside(true, closeActiveMarker);

  const {searchData} = useSearchData();
  const viewDetails = () => {
    navigate({
      pathname: `/venue/${accommodationId}/details`,
      search: queryString.stringify(searchData),
    });
  };

  const isCorporateVenue =
    accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const isWorkDesk = roomType === ERoomType.WORK_SPACE;

  const cardTitle = useMemo(() => {
    switch (true) {
      case isCorporateVenue:
        return 'Office';
      case type === EVenueType.INSTANT || isWorkDesk:
        return 'Instant booking';
      case type === EVenueType.RTB:
        return 'Request booking';
      default:
        return 'Request pricing';
    }
  }, [isCorporateVenue, type, isWorkDesk]);

  const [isRequestForOfferBtnShowed] = useIsRequestForOfferBtnVisible({
    isCorporateVenue,
  });

  return (
    <Card ref={element}>
      <Row>
        <ImageContainer onClick={viewDetails}>
          <Image
            alt={venue.name}
            src={coverImageLink}
            ratio={EAspectRation['4x3']}
            isSearchVenue
          />
        </ImageContainer>

        <ContentBlock>
          <Row>
            <BookInstantlyContainer>
              <TextValue size='small' marginTop='0' black>
                {cardTitle}
              </TextValue>
            </BookInstantlyContainer>

            {hasPrice && (
              <BookInstantlyContainer>
                {isCorporateVenue ? (
                  <TotalPriceText>Free</TotalPriceText>
                ) : (
                  <TotalPriceText id='activeMarkerVenueStartsFromPrice'>
                    {totalPrice
                      ? `${formatFloatWithOneNumber(
                          totalPrice,
                        )} ${getCurrencySymbol(currency)}`
                      : 'n/a'}
                  </TotalPriceText>
                )}
              </BookInstantlyContainer>
            )}
          </Row>

          <Row marginBottom={16}>
            <VenueName id='activeMarkerVenueName'>{name}</VenueName>
          </Row>

          <ButtonContainer>
            {isCorporateVenue ? (
              <NW2Button
                buttonType='primary'
                inline
                size='small'
                onClick={viewDetails}
              >
                Continue
              </NW2Button>
            ) : hasPrice ? (
              <>
                {isRequestForOfferBtnShowed && (
                  <NW2Button
                    buttonType='chip'
                    size='small'
                    active={isShortlisted}
                    style={{padding: '8px 14px'}}
                    iconPlace='left'
                    icon={
                      (isShortlisted || hasPrice) && (
                        <Icon
                          icon={getIcon(isShortlisted, hasPrice)}
                          size={17}
                          transparent
                        />
                      )
                    }
                    onClick={onRequestForOfferClick(venue)}
                    minimized={hasPrice}
                  />
                )}

                <NW2Button
                  buttonType={isShortlisted ? 'tertiary' : 'primary'}
                  inline
                  size='small'
                  id='activeMarkerButton'
                  onClick={viewDetails}
                >
                  Book
                </NW2Button>
              </>
            ) : (
              isRequestForOfferBtnShowed && (
                <NW2Button
                  buttonType='chip'
                  size='small'
                  active={isShortlisted}
                  iconPlace='left'
                  icon={
                    isShortlisted && (
                      <Icon icon={getIcon(true, false)} size={17} transparent />
                    )
                  }
                  onClick={onRequestForOfferClick(venue)}
                  minimized={hasPrice}
                >
                  {isShortlisted ? 'Shortlisted' : 'Request for offer'}
                </NW2Button>
              )
            )}
          </ButtonContainer>
        </ContentBlock>
      </Row>
      <AmenitiesRowContainer>
        {/* TODO: check if we need this functionality */}
        {/* {isFullyBooked && (
          <TextValue error id='activeMarkerFullyBookedText'>
            Fully booked
          </TextValue>
        )} */}
      </AmenitiesRowContainer>
    </Card>
  );
};

export default NW2VenuesListMapItem;
