import React, {useCallback, useEffect, useState} from 'react';

import InputBlock from '../InputBlock/InputBlock';
import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import {EditBedrooms} from 'view/components/EditBedrooms/EditBedrooms';

import {SEARCH_GROUPS_ROOMS} from '../../constants';
import {MINIMAL_GROUP_QTY} from 'constants/app';
import {offsetDef} from 'constants/styleVars';
import {
  ACCOMMODATION_FORM_FIELD_NAME,
  BEDROOM_EXTRAS,
} from 'constants/bedrooms';
import {useClickOutside} from 'hooks/useClickOutside';
import useToggle from 'hooks/useToggle';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  setGroupsRooms,
  setSearchCriteriaMeetingRoomCapacity,
} from 'store/search/searchSlice';
import {EBedroomExtrasNames} from 'types/dto/IExtras.type';
import {
  TBedroomsCatering,
  THandleSetBedroomsCatering,
  TInitialFormValues,
} from './types';
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownBox,
  Wrapper,
} from './GroupsRooms.styles';
import {useGroupsRoomsData} from './useGroupsRoomsData';

interface IProps {
  onFocusHandler: () => void;
}
export function GroupsRooms({onFocusHandler}: IProps) {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const focusedInput = useAppSelector(({app}) => app.searchFocusedInput);
  const timeData = useAppSelector(({search}) => search.initialTimeData);
  const isHeaderCollapsed = useAppSelector(({app}) => app.headerCollapsed);

  const {single, double} = useGroupsRoomsData();

  const [isOpened, toggleOpened] = useToggle(false);

  const [bedroomsCatering, setBedroomsCatering] = useState<TBedroomsCatering>(
    {},
  );

  useEffect(() => {
    if (isOpened && isHeaderCollapsed) {
      onFocusHandler();
    }
  }, [isHeaderCollapsed, isOpened, onFocusHandler]);

  const [roomsCount, setRoomsCount] = useState(MINIMAL_GROUP_QTY);
  const [isMinQtyError, setMinQtyError] = useState(false);

  const date = timeData[0].timeStart;
  const cateringData = bedroomsCatering[date];

  const handleSetBedroomsCatering: THandleSetBedroomsCatering = useCallback(
    ({roomType, name, withBreakfast, qty, isEnabled, date}) => {
      setMinQtyError(false);

      setBedroomsCatering((prev) => ({
        ...prev,
        [date]: {
          ...prev[date],
          [roomType]: {
            ...prev[date]?.[roomType],
            name: name || prev[date]?.[roomType]?.name,
            withBreakfast:
              withBreakfast ?? prev[date]?.[roomType]?.withBreakfast,
            qty: qty ?? prev[date]?.[roomType]?.qty,
            isEnabled: isEnabled ?? prev[date]?.[roomType]?.isEnabled,
          },
        },
      }));
    },
    [],
  );

  useEffect(() => {
    // INIT CATERING
    if (cateringData) return;

    handleSetBedroomsCatering({
      roomType: 'single',
      name: EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST,
      withBreakfast: single ? single.withBreakfast : true,
      isEnabled: single ? single.isEnabled : true,
      qty: single ? single.qty : MINIMAL_GROUP_QTY,
      date,
    });

    handleSetBedroomsCatering({
      roomType: 'double',
      name: EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST,
      withBreakfast: double ? double.withBreakfast : true,
      isEnabled: double ? double.isEnabled : false,
      qty: double ? double.qty : 1,
      date,
    });

    const roomsQty = single ? single.qty : MINIMAL_GROUP_QTY;
    dispatch(setSearchCriteriaMeetingRoomCapacity(roomsQty));
  }, [cateringData, date, dispatch, double, handleSetBedroomsCatering, single]);

  const singleRoomDay = cateringData?.single;
  const doubleRoomDay = cateringData?.double;

  const [initialFormValues, setInitialFormValues] =
    useState<TInitialFormValues | null>(null);

  useEffect(() => {
    if (initialFormValues) return;

    const enabled = [];

    let roomsQty = 0;

    if (single?.isEnabled) {
      enabled.push(BEDROOM_EXTRAS.SINGLE);
      roomsQty += single.qty;
    }

    if (double?.isEnabled) {
      enabled.push(BEDROOM_EXTRAS.DOUBLE);
      roomsQty += double.qty;
    }

    setInitialFormValues({
      [BEDROOM_EXTRAS.SINGLE]: single?.qty || 10,
      [BEDROOM_EXTRAS.DOUBLE]: double?.qty || 1,
      [ACCOMMODATION_FORM_FIELD_NAME]: enabled,
    });

    setRoomsCount(roomsQty);
  }, [initialFormValues, isOpened, double, single]);

  const onSave = useCallback(
    (formData: Record<string, any>) => {
      const roomsQty = formData.accommodation.reduce(
        (acc: Record<string, any>, current: number) =>
          acc + formData[`${current}`],
        0,
      );

      if (roomsQty >= MINIMAL_GROUP_QTY) {
        setRoomsCount(roomsQty);

        dispatch(
          setGroupsRooms({
            single: {
              isEnabled: !!singleRoomDay?.isEnabled,
              qty: singleRoomDay?.qty as number,
              withBreakfast: singleRoomDay?.withBreakfast,
            },
            double: {
              isEnabled: !!doubleRoomDay?.isEnabled,
              qty: doubleRoomDay?.qty as number,
              withBreakfast: doubleRoomDay?.withBreakfast,
            },
          }),
        );

        dispatch(setSearchCriteriaMeetingRoomCapacity(roomsQty));

        toggleOpened();

        const enabled = [];

        if (singleRoomDay?.isEnabled) {
          enabled.push(BEDROOM_EXTRAS.SINGLE);
        }

        if (doubleRoomDay?.isEnabled) {
          enabled.push(BEDROOM_EXTRAS.DOUBLE);
        }

        setInitialFormValues({
          [BEDROOM_EXTRAS.SINGLE]: singleRoomDay?.qty || 10,
          [BEDROOM_EXTRAS.DOUBLE]: doubleRoomDay?.qty || 1,
          [ACCOMMODATION_FORM_FIELD_NAME]: enabled,
        });
      } else {
        setMinQtyError(true);
      }
    },
    [dispatch, doubleRoomDay, singleRoomDay, toggleOpened],
  );

  const onCancel = useCallback(() => {
    const isSingleDayNameChanged =
      singleRoomDay &&
      (single.withBreakfast !== singleRoomDay.withBreakfast ||
        single.qty !== singleRoomDay?.qty);
    const isDoubleDayNameChanged =
      doubleRoomDay &&
      (double.withBreakfast !== doubleRoomDay.withBreakfast ||
        double.qty !== doubleRoomDay?.qty);

    if (isSingleDayNameChanged || isDoubleDayNameChanged) {
      setInitialFormValues(null);
    }

    // rollback values if they are not saved
    if (isSingleDayNameChanged) {
      handleSetBedroomsCatering({
        roomType: 'single',
        name: EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST,
        withBreakfast: single.withBreakfast,
        qty: single.qty,
        date,
      });
    }

    if (isDoubleDayNameChanged) {
      handleSetBedroomsCatering({
        roomType: 'double',
        name: EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST,
        withBreakfast: double.withBreakfast,
        qty: double.qty,
        date,
      });
    }

    toggleOpened();
  }, [
    date,
    doubleRoomDay,
    handleSetBedroomsCatering,
    singleRoomDay,
    double,
    single,
    toggleOpened,
  ]);

  const [containerRef] = useClickOutside(isOpened, onCancel);

  const inputBlock = (
    <InputBlock
      htmlFor={SEARCH_GROUPS_ROOMS}
      labelText='Rooms'
      isFocused={focusedInput === SEARCH_GROUPS_ROOMS}
      onClick={toggleOpened}
      isWidthAuto
      isNoMargin
      isNoPadding
      isNoBorder
    >
      {roomsCount}
    </InputBlock>
  );

  const editAccommodation = (
    <EditBedrooms
      onSave={onSave}
      onCancel={onCancel}
      bedroomsCatering={bedroomsCatering}
      initialValues={initialFormValues || {}}
      options={Object.values(BEDROOM_EXTRAS)}
      handleSetBedroomsCatering={handleSetBedroomsCatering}
      date={date}
      errorMsg={
        isMinQtyError ? `Minimum group size ${MINIMAL_GROUP_QTY} rooms` : ''
      }
      size={isMobile ? 'large' : 'medium'}
      actionsSection={
        isMobile ? (
          <DrawerFooter>
            <NMMSubmitSection
              submitLabel='next'
              cancelLabel='clear'
              handleCancel={onCancel}
              disabled={isMinQtyError}
              gap={offsetDef}
              margin={`${offsetDef} 0 0`}
              justifyContent='space-between'
              size='medium'
            />
          </DrawerFooter>
        ) : (
          <NMMSubmitSection
            submitLabel='update'
            cancelLabel='cancel'
            handleCancel={onCancel}
            disabled={isMinQtyError}
            gap={offsetDef}
          />
        )
      }
    />
  );

  return (
    <Wrapper ref={!isMobile ? containerRef : null}>
      {inputBlock}

      {cateringData &&
        (isMobile ? (
          <StyledAntdDrawer
            title={null}
            onClose={toggleOpened}
            open={isOpened}
            height='auto'
          >
            <div ref={containerRef}>
              <DrawerHeader>{inputBlock}</DrawerHeader>
              <DrawerContent>{editAccommodation}</DrawerContent>
            </div>
          </StyledAntdDrawer>
        ) : (
          isOpened && <DropdownBox>{editAccommodation}</DropdownBox>
        ))}
    </Wrapper>
  );
}
