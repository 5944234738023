import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import CustomModal from 'view/components/CustomModal';
import NW2Button from 'view/components/NW2Button';
import BookingChangeOverviewSidebarBody from './components/BookingChangeOverviewSidebarBody';
import BookingChangeSidebarBodyDecline from './components/BookingChangeSidebarBodyDecline';
import BookingChangeSidebarBodyConfirm from './components/BookingChangeSidebarBodyConfirm';
import {useAppDispatch} from 'store/hooks';

import {StyledSpan} from 'view/components/Typography';
import {formatFloatWithOneNumber} from 'utils/venueUtils';

import {formatDate} from 'utils/stringUtils';
import {EBookingStatus} from 'types/booking';
import {IBookingChanges} from 'types/bookingOrders';
import {
  contentMaxWidthXs,
  fontSizeLg,
  fontSizeXSm,
  fontSizeXXXLg,
  fontWeightBold,
} from 'constants/styleVars';
import {
  ButtonBlock,
  ConfirmButton,
  FooterContainer,
  HeaderSubTitle,
  HeaderTitle,
  HeaderWrapper,
  PriceBlock,
  PriceTextContainer,
} from './BookingChangeOverviewSidebar.styles';
import {
  confirmOrderChanges,
  declineOrderChanges,
} from 'store/bookingsSupplier/actions';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from '../../../types/dto/EUserRoleCognito';

export type TDeclineFormData = {
  declinedReason: string;
  isUnavailable?: boolean;
  additionalMessage?: string;
  approverName: string;
};
export type TConfirmFormData = {
  approverName: string;
};

type TProps = {
  isShowModal: boolean;
  bookingChanges: IBookingChanges[];
  currencySymbol?: string;
  onCloseHandler: () => void;
  orderNumber: string;
  handleChangesStatusModal: (status: boolean) => void;
  bookingChangesObj: Record<EBookingStatus, IBookingChanges>;
};
const BookingChangeOverviewSidebar = ({
  isShowModal,
  bookingChanges,
  currencySymbol,
  onCloseHandler,
  orderNumber,
  handleChangesStatusModal,
  bookingChangesObj,
}: TProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeclineMode, setDeclineMode] = useState(false);
  const [isConfirmMode, setConfirmMode] = useState(false);

  // TODO: Remove with future iterations of AgentView
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  if (!bookingChanges.length) return null;

  const newCustomerOrder =
    bookingChangesObj[EBookingStatus.RTC_PENDING] ||
    bookingChangesObj[EBookingStatus.CONFIRMED] ||
    bookingChangesObj[EBookingStatus.PASSED] ||
    bookingChangesObj[EBookingStatus.CANCELED];

  const isNewCustomerOrderRTC =
    newCustomerOrder?.bookingStatus === EBookingStatus.RTC_PENDING;

  const prevCustomerOrder = isNewCustomerOrderRTC
    ? bookingChangesObj[EBookingStatus.CONFIRMED]
    : bookingChangesObj[EBookingStatus.EDIT_CANCELED_BY_CONFIRM] ||
      bookingChangesObj[EBookingStatus.EDIT_MODE_CANCELED] ||
      bookingChangesObj[EBookingStatus.RTC_CANCELLED] ||
      bookingChangesObj[EBookingStatus.RTC_EXPIRED] ||
      bookingChangesObj[EBookingStatus.RTC_DECLINED];

  const handleDeclineFormSubmit = (formData: TDeclineFormData) => {
    searchParams.delete('isRTC');
    searchParams.delete('orderNumber');
    setSearchParams(searchParams);
    dispatch(
      declineOrderChanges({
        orderId: newCustomerOrder?.orderId,
        orderNumber,
        isDeclineMode,
        ...formData,
      }),
    );
    setDeclineMode(false);
    handleChangesStatusModal(true);
    onCloseHandler();
  };
  const handleConfirmFormSubmit = (formData: TConfirmFormData) => {
    dispatch(
      confirmOrderChanges({
        orderId: newCustomerOrder?.orderId,
        orderNumber,
        isConfirmMode,
        ...formData,
      }),
    );
    setConfirmMode(false);
    onCloseHandler();
  };

  const onClickDeclineHandler = () => {
    if (isConfirmMode) {
      setDeclineMode(false);
      setConfirmMode(false);
    } else {
      setDeclineMode(!isDeclineMode);
    }
  };

  const onClickConfirmHandler = () => {
    if (!isDeclineMode && !isConfirmMode) {
      setConfirmMode(!isConfirmMode);
    }
  };

  const onModalCloseHandler = () => {
    setDeclineMode(false);
    setConfirmMode(false);
    onCloseHandler();
  };

  const header = (
    <HeaderWrapper>
      <HeaderTitle>Latest change</HeaderTitle>
      <HeaderSubTitle>
        Last updated on the{' '}
        {formatDate(newCustomerOrder.createdAt, 'd MMM, YYYY', 'en-US')}
      </HeaderSubTitle>
    </HeaderWrapper>
  );

  const footer = isNewCustomerOrderRTC && (
    <FooterContainer>
      <PriceBlock>
        <PriceTextContainer>
          <StyledSpan fontSize={fontSizeLg} fontWeight={fontWeightBold}>
            New total
          </StyledSpan>
          <StyledSpan fontSize={fontSizeXSm} NW2Gray300>
            Includes taxes and fees
          </StyledSpan>
        </PriceTextContainer>
        <StyledSpan fontSize={fontSizeXXXLg} fontWeight={fontWeightBold}>
          {formatFloatWithOneNumber(newCustomerOrder.totalPrice)}
          &nbsp;
          {currencySymbol}
          &nbsp;
        </StyledSpan>
      </PriceBlock>
      {!isAgent && (
        <ButtonBlock>
          <NW2Button buttonType='secondary' onClick={onClickDeclineHandler}>
            {isDeclineMode || isConfirmMode ? 'back' : 'decline'}
          </NW2Button>
          {!isDeclineMode && !isConfirmMode && (
            <ConfirmButton buttonType='primary' onClick={onClickConfirmHandler}>
              confirm
            </ConfirmButton>
          )}

          {(isDeclineMode || isConfirmMode) && (
            <ConfirmButton
              buttonType='primary'
              type={isDeclineMode || isConfirmMode ? 'submit' : undefined}
              form={isDeclineMode ? 'declineForm' : 'confirmForm'}
              onClick={onClickConfirmHandler}
            >
              confirm
            </ConfirmButton>
          )}
        </ButtonBlock>
      )}
    </FooterContainer>
  );

  if (!prevCustomerOrder || !newCustomerOrder) return null;

  return (
    <CustomModal
      isShowModal={isShowModal}
      onClose={onModalCloseHandler}
      isMask={false}
      header={header}
      position='topRight'
      width={contentMaxWidthXs}
      footer={footer}
    >
      {isDeclineMode ? (
        <BookingChangeSidebarBodyDecline
          handleFormSubmit={handleDeclineFormSubmit}
        />
      ) : isConfirmMode ? (
        <BookingChangeSidebarBodyConfirm
          handleFormSubmit={handleConfirmFormSubmit}
        />
      ) : (
        <BookingChangeOverviewSidebarBody
          newCustomerOrder={newCustomerOrder}
          prevCustomerOrder={prevCustomerOrder}
          currencySymbol={currencySymbol}
        />
      )}
    </CustomModal>
  );
};
export default BookingChangeOverviewSidebar;
