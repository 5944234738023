import React, {useMemo} from 'react';

import Icon from 'view/components/Icon';
import Image from 'view/components/Image';
import TruncateText from 'view/components/TruncateText';
import AccommodationTitle from 'view/venue/components/AccommodationTitle';

import {UnitCardRow} from './UnitCardRow';

import {
  IPreviewExtraOption,
  IPreviewState,
  TExtraPriceField,
} from '../../../types';
import {IAmenity} from 'types/dto/IAmenity';
import {ERoomSchema, IUnitDimensions} from 'types/venue';
import {IRequestDayItem} from 'types/offer';
import DateUtils from 'utils/dateUtils';
import {pluralize} from 'utils/helpers';
import {getFloorName} from 'utils/getFloorName';
import {matchNumberToSecondDecimal} from 'utils/stringUtils';
import {dimensionsConverter, formatFloatWithOneNumber} from 'utils/venueUtils';
import {UnitCardExtrasList} from './UnitCardExtrasList';
import {
  AmenitiesBlock,
  AmenityItem,
  AmenityItemIcon,
  SubTotalPrice,
  UnitCardExtraPrice,
  UnitCardFooter,
  UnitCardHeader,
  UnitCardItem,
  UnitCardRentalPrice,
  UnitCardTitle,
} from './UnitCard.styles';
import {borderRadiusDef} from 'constants/styleVars';

interface ICardProps {
  currency: string;
  previewState: Partial<IPreviewState> | Record<string, never>;
  roomRentalPrice: number;
  description: string;
  roomAmenities: IAmenity[];
  checkedAmenities: number[];
  coverImg?: string;
  dimensions?: IUnitDimensions;
  maxParticipants?: string | number;
  floor?: string;
  unit: IRequestDayItem;
  unitTitle: string;
  unitName: string;
  equipmentPrices?: TExtraPriceField;
  cateringPrices?: TExtraPriceField;
  bedroomsPrices?: TExtraPriceField;
  equipmentExtras: IPreviewExtraOption[];
  cateringExtras: IPreviewExtraOption[];
  bedroomExtras: IPreviewExtraOption[];
  subTotalPrice: number;
  isEquipmentInitialised?: boolean;
  isCateringInitialised?: boolean;
  isBedroomsInitialised?: boolean;
  isLastUnit?: boolean;
}
export function UnitCard({
  currency,
  previewState,
  roomRentalPrice,
  description,
  roomAmenities,
  checkedAmenities,
  coverImg,
  dimensions,
  floor,
  maxParticipants,
  unit,
  unitTitle,
  unitName,
  equipmentPrices,
  cateringPrices,
  equipmentExtras,
  cateringExtras,
  bedroomsPrices,
  bedroomExtras,
  subTotalPrice,
  isEquipmentInitialised,
  isCateringInitialised,
  isBedroomsInitialised,
  isLastUnit,
}: ICardProps) {
  const {
    isAmenitiesHidden,
    isAmenitiesFilled,
    isFloorsInitialised,
    isDimensionsInitialised,
    isCapacityInitialised,
  } = previewState;

  const amenities = useMemo(
    () =>
      roomAmenities.map(({id, name}) => {
        const isChecked = checkedAmenities?.includes(id);

        if (isAmenitiesFilled && !isChecked) return null;

        return (
          <UnitCardRow
            key={`preview-unit-amenity-${id}`}
            data={
              isChecked && (
                <AmenityItem>
                  <AmenityItemIcon>
                    <Icon icon='NW2_TICK' />
                  </AmenityItemIcon>
                  {name}
                </AmenityItem>
              )
            }
          />
        );
      }),
    [checkedAmenities, isAmenitiesFilled, roomAmenities],
  );

  return (
    <div id={unit.id}>
      <UnitCardTitle mb={16}>{unitTitle}</UnitCardTitle>

      <UnitCardHeader>
        <UnitCardRow
          data={
            coverImg && (
              <Image
                height='150px'
                borderRadius={borderRadiusDef}
                src={coverImg}
                alt='space cover img'
                data-testid='booking-details-unit-card-image'
              />
            )
          }
          minHeight='150px'
        />

        <div>
          <UnitCardItem mb={16}>
            <UnitCardRow data={unitName && <b>{unitName}</b>} />
          </UnitCardItem>
          <UnitCardItem>
            Time:{' '}
            <b>
              {DateUtils.getHoursAndMinutes(unit.checkIn)} -{' '}
              {DateUtils.getHoursAndMinutes(unit.checkOut)}
            </b>
          </UnitCardItem>
          <UnitCardItem>
            Room setup: <b>{ERoomSchema[unit.setupStyle]}</b>
          </UnitCardItem>
          <UnitCardItem>
            Participants: <b>{pluralize('people', unit.participants)}</b>{' '}
            <UnitCardRow
              data={
                isCapacityInitialised &&
                maxParticipants && <b>({maxParticipants} max)</b>
              }
              minWidth='70px'
            />
          </UnitCardItem>
          <UnitCardItem>
            <UnitCardRow
              data={
                isFloorsInitialised &&
                floor && (
                  <>
                    Floor: <b>{getFloorName(Number(floor))}</b>
                  </>
                )
              }
            />
          </UnitCardItem>
        </div>
      </UnitCardHeader>

      <div>
        <UnitCardItem>
          <UnitCardRow
            data={
              isDimensionsInitialised &&
              dimensions?.area && (
                <>
                  Size: {matchNumberToSecondDecimal(dimensions.area)} square
                  metres / {dimensionsConverter('m', dimensions.area, true)}{' '}
                  square feet
                </>
              )
            }
          />
        </UnitCardItem>
        <UnitCardItem mb={16}>
          <UnitCardRow
            data={
              isDimensionsInitialised &&
              dimensions?.ceilingHeight && (
                <>
                  Ceiling height:{' '}
                  {matchNumberToSecondDecimal(dimensions.ceilingHeight)} metres
                  / {dimensionsConverter('m', dimensions.ceilingHeight)} feet
                </>
              )
            }
          />
        </UnitCardItem>

        {!isAmenitiesHidden && (
          <UnitCardItem mb={16}>
            <AmenitiesBlock>{amenities}</AmenitiesBlock>
          </UnitCardItem>
        )}

        <UnitCardItem mb={16}>
          <UnitCardRow
            data={
              description && (
                <TruncateText
                  text={description}
                  numberOfLines={4}
                  hideToggler
                />
              )
            }
            minHeight='96px'
          />
        </UnitCardItem>

        <UnitCardItem mb={!equipmentExtras.length && !isLastUnit ? 32 : 8}>
          <UnitCardRentalPrice>
            Room rental:{' '}
            <UnitCardRow
              data={
                roomRentalPrice && (
                  <UnitCardExtraPrice>
                    {formatFloatWithOneNumber(roomRentalPrice)} {currency}
                  </UnitCardExtraPrice>
                )
              }
              minWidth='120px'
            />
          </UnitCardRentalPrice>
        </UnitCardItem>

        {equipmentExtras.length > 0 && (
          <UnitCardExtrasList
            extrasList={equipmentExtras}
            extrasPrices={equipmentPrices}
            isInitialised={isEquipmentInitialised}
            currency={currency}
          />
        )}

        {cateringExtras.length > 0 && isLastUnit && (
          <>
            <UnitCardTitle mb={16}>Food & beverage</UnitCardTitle>
            <UnitCardExtrasList
              extrasList={cateringExtras}
              extrasPrices={cateringPrices}
              isInitialised={isCateringInitialised}
              currency={currency}
            />
          </>
        )}

        {bedroomExtras.length > 0 && isLastUnit && (
          <>
            <AccommodationTitle checkIn={unit.checkIn} />

            <UnitCardExtrasList
              extrasList={bedroomExtras}
              extrasPrices={bedroomsPrices}
              isInitialised={isBedroomsInitialised}
              currency={currency}
              isBedrooms
            />
          </>
        )}
      </div>

      {isLastUnit && (
        <UnitCardFooter>
          <UnitCardTitle>Day sub-total:</UnitCardTitle>
          <UnitCardRow
            data={
              subTotalPrice && (
                <SubTotalPrice>
                  {formatFloatWithOneNumber(subTotalPrice)} {currency}
                </SubTotalPrice>
              )
            }
            minWidth='120px'
          />
        </UnitCardFooter>
      )}
    </div>
  );
}
