import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import HeroSlider from 'view/mainLanding/components/HeroSlider';
import CardsBlock from 'view/mainLanding/components/CardsBlock';
import GetInTouchSection from 'view/mainLanding/components/GetInTouchSection';
import NW2SearchSection from '../components/NW2SearchSection/NW2SearchSection';

import {CallToAction} from 'view/components/CallToAction';
import {OptionsSection} from 'view/mainLanding/components/OptionsSection';
import {PreFooter} from 'view/components/PreFooter';
import {setOpenRegisterPopup} from 'store/app/appSlice';
import {useQuery} from 'hooks/useQuery';

import {
  NW2Container,
  NW2MainLandingContainer,
  NW2Section,
} from './MainLanding.styles';

function MainLanding() {
  const dispatch = useDispatch();
  const {register} = useQuery();

  useEffect(() => {
    if (register) {
      dispatch(setOpenRegisterPopup({openRegisterPopup: true}));
    }
  }, [register, dispatch]);

  return (
    <>
      <NW2SearchSection />

      <NW2MainLandingContainer>
        <HeroSlider />
        <NW2Section>
          <NW2Container>
            <CardsBlock />
          </NW2Container>
        </NW2Section>

        <OptionsSection />

        <CallToAction
          text='Ready to find your next space?'
          buttonText='Get Started'
        />

        <NW2Section>
          <GetInTouchSection />
        </NW2Section>

        <PreFooter />
      </NW2MainLandingContainer>
    </>
  );
}

export default MainLanding;
