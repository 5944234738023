import React, {useMemo} from 'react';

import PrePostDateBlock from './PrePostDateBlock';

import {OrdersAccommodationExtras} from '../AccommodationExtras/OrdersAccommodationExtras';

import {formatFloatWithOneNumber} from 'utils/venueUtils';
import {Container} from './AccommodationBlock.styles';
import {MainWrapper} from './AccommodationBlock.styles';
import {
  DaySubTotal,
  DaySubTotalPrice,
  UnitDayPrice,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {offsetXLg} from 'constants/styleVars';
import {EEventType} from 'types/venue';
import {ISummaryExtra} from 'types/dto/IBooking.types';

type TProps = {
  eventType: EEventType;
  bedrooms: ISummaryExtra[];
  checkIn: string;
  isOffer: boolean;
  currencySymbol?: string;
  titleMargin?: string;
  index: number;
};

const OrdersAccommodationBlock = ({
  bedrooms,
  checkIn,
  isOffer,
  currencySymbol,
  titleMargin,
  eventType,
  index,
}: TProps) => {
  const daySubTotalPrice = useMemo(
    () =>
      bedrooms.reduce((acc: number, item: ISummaryExtra) => {
        if (item.totalPrice) {
          return acc + item.totalPrice;
        }
        return acc;
      }, 0),
    [bedrooms],
  );

  return (
    <MainWrapper>
      <PrePostDateBlock checkIn={checkIn} eventType={eventType} index={index} />
      <Container>
        <OrdersAccommodationExtras
          unitExtras={bedrooms}
          currencySymbol={currencySymbol}
          margin={titleMargin}
        />

        {isOffer && (
          <UnitDayPrice margin={`${offsetXLg} 0 0`}>
            <DaySubTotal>Day sub-total:</DaySubTotal>
            <DaySubTotalPrice>
              {formatFloatWithOneNumber(daySubTotalPrice)}
              &nbsp; {currencySymbol}
            </DaySubTotalPrice>
          </UnitDayPrice>
        )}
      </Container>
    </MainWrapper>
  );
};

export default OrdersAccommodationBlock;
