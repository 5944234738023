import React from 'react';

import Slide1 from 'img/slide-1.webp';
import styled from 'styled-components';
import {
  fontWeightExtraBold,
  lgBp,
  mdBp,
  offsetDef,
  offsetXXLg,
  offsetXXXLg,
  whiteColor,
} from 'constants/styleVars';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import Image from '../../components/Image';
import {StyledH3} from '../../components/Typography';

const HeroSliderContainer = styled.div`
  position: relative;
`;

const SliderTitle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 1;
  padding: 0;
  font-weight: ${fontWeightExtraBold};
  color: ${whiteColor};
  font-size: 44px;
  line-height: 1;
  text-transform: uppercase;

  span {
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 4px;
      background: ${whiteColor};
      @media (min-width: ${mdBp}px) {
        height: 9px;
        bottom: -${offsetDef};
      }
    }
  }

  @media (min-width: ${mdBp}px) {
    font-size: 66px;
    bottom: ${offsetXXLg};
  }
  @media (min-width: ${lgBp}px) {
    font-size: 87px;
    bottom: ${offsetXXXLg};
  }
`;

function HeroSlider() {
  return (
    <HeroSliderContainer>
      <SliderTitle>
        <NW2Container>HRS Connect</NW2Container>
        <StyledH3 offset={offsetXXLg} NW2Gray100Color>
          <NW2Container>
            Book global meeting venues
            <br />
            and workspaces.
          </NW2Container>
        </StyledH3>
      </SliderTitle>
      <Image height='456px' src={Slide1} />
    </HeroSliderContainer>
  );
}

export default HeroSlider;
