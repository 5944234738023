import React from 'react';

import {RequestDetailsUnit} from './RequestDetailsUnit';
import {getPrice} from '../../helpers';

import {
  ERequestDetailsSections,
  IOfferDay,
  IOfferRequestUnit,
  IOfferUnitExtra,
} from 'types/offer';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {formatDate} from 'utils/stringUtils';
import {
  formatFloatWithOneNumber,
  getFilteredExtrasByType,
} from 'utils/venueUtils';
import {
  DayContent,
  DaysBlock,
  DaySubTotal,
  DaySubTotalPrice,
  DayTitle,
  ExtrasTitle,
  UnitDayPrice,
  Wrapper,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {AccommodationExtras} from '../AccommodationExtras/AccommodationExtras';
import {RequestUnitExtras} from './RequestUnitExtras';
import {
  CardBox,
  Container,
} from '../OfferRequestAccommodationBlock/AccommodationBlock.styles';
import {offsetDef, offsetXLg} from 'constants/styleVars';

interface IProps {
  isSingle: boolean;
  isOffer: boolean;
  days: IOfferDay[];
  extrasOption: IExtrasOption[];
  currencySymbol?: string;
  isRequestWithBedroom: boolean;
  isRequestWithUnitId?: boolean;
}
export function OfferRequestDetailsUnits({
  currencySymbol,
  extrasOption,
  isSingle,
  isOffer,
  days,
  isRequestWithBedroom,
  isRequestWithUnitId,
}: IProps) {
  return (
    <>
      {days.map((day, index) => {
        const date = formatDate(day.checkIn, 'd MMM, YYYY', 'en-US');
        const dayId = `Day ${index + 1}`;
        const isUnitSingle = day.items.length === 1;

        const dayItemsPrice = getPrice(day.items);
        const accommodationPrice = getPrice(day.bedrooms);
        const foodAndBeveragePrice = getPrice(
          day.foodAndBeverage as IOfferUnitExtra[],
        );

        const foodAndBeverage = getFilteredExtrasByType(
          day.foodAndBeverage as IOfferUnitExtra[],
          EResourcesType.FOOD_AND_BEVERAGE,
          extrasOption,
        );

        return (
          <section
            key={day.checkIn}
            id={
              isSingle
                ? isOffer
                  ? ERequestDetailsSections.OFFER_DETAILS
                  : ERequestDetailsSections.REQUEST_DETAILS
                : ''
            }
          >
            <Wrapper>
              <DaysBlock id={dayId}>
                <DayTitle>{isSingle ? 'Details' : dayId}</DayTitle>
                <DayContent>{date}</DayContent>
              </DaysBlock>

              <Container>
                {day.items.map((unit: IOfferRequestUnit, idx) => (
                  <CardBox key={unit.id}>
                    <RequestDetailsUnit
                      unit={unit}
                      index={idx}
                      isUnitSingle={isUnitSingle}
                      isOffer={isOffer}
                      currencySymbol={currencySymbol}
                      isRequestWithBedroom={isRequestWithBedroom}
                      isRequestWithUnitId={isRequestWithUnitId}
                      date={day.checkIn}
                    />
                  </CardBox>
                ))}

                {!!foodAndBeverage?.length && (
                  <CardBox>
                    <ExtrasTitle
                      margin={!day.items.length ? `0 0 ${offsetDef}` : ''}
                    >
                      Food & beverage
                    </ExtrasTitle>
                    <RequestUnitExtras
                      data={foodAndBeverage}
                      isOffer={isOffer}
                      isRequestWithUnitId={isRequestWithUnitId}
                      currencySymbol={currencySymbol}
                    />
                  </CardBox>
                )}

                {!!day.bedrooms.length && (
                  <CardBox
                  // noTopMargin={!foodAndBeverage?.length && !day.items.length}
                  >
                    <AccommodationExtras
                      checkIn={day.checkIn}
                      unitExtras={day.bedrooms}
                      extrasOption={extrasOption}
                      currencySymbol={currencySymbol}
                      isOffer={isOffer}
                      isRequestWithUnitId={isRequestWithUnitId}
                    />
                  </CardBox>
                )}

                {isOffer && (
                  <UnitDayPrice margin={`${offsetXLg} 0 0`}>
                    <DaySubTotal>Day sub-total:</DaySubTotal>
                    <DaySubTotalPrice>
                      {formatFloatWithOneNumber(
                        dayItemsPrice +
                          foodAndBeveragePrice +
                          accommodationPrice,
                      )}
                      &nbsp; {currencySymbol}
                    </DaySubTotalPrice>
                  </UnitDayPrice>
                )}
              </Container>
            </Wrapper>
          </section>
        );
      })}
    </>
  );
}
