import React from 'react';

import DateRangeString from 'view/components/DateRangeString';

import {ERequestCellName} from 'types/dto/IBookingType.type';
import {formatPriceTwoDigitsOrDash} from 'view/venue/NW2VenueProfile/components/NMMResources/helpers';
import {BlockCell} from 'view/supplier/Bookings/BookingListRooms/Requests/BlockCell';

interface IProps {
  number: string;
  checkInDate: string;
  checkOutDate: string;
  holdUp: string;
  company: string;
  totalPrice?: number;
  currencySymbol: string;
  isRequest?: boolean;
  isCustomer?: boolean;
  isRequestWithBedroom?: boolean;
}

const RequestModalDetails = ({
  number,
  checkInDate,
  checkOutDate,
  company,
  currencySymbol,
  holdUp,
  totalPrice,
  isRequest,
  isCustomer,
  isRequestWithBedroom,
}: IProps) => {
  return (
    <>
      <BlockCell
        data={`#${number}`}
        title={isCustomer ? 'Offer ID' : 'Request ID'}
      />

      {!isCustomer && (
        <BlockCell data={`${holdUp} ago`} title={ERequestCellName.RECEIVED} />
      )}

      <BlockCell
        data={<DateRangeString checkIn={checkInDate} checkOut={checkOutDate} />}
        title='Dates requested'
      />

      {totalPrice && (
        <BlockCell
          data={
            isRequestWithBedroom
              ? '-'
              : formatPriceTwoDigitsOrDash(totalPrice, currencySymbol)
          }
          title={
            isRequest && !isCustomer
              ? ERequestCellName.POTENTIAL_WORTH
              : ERequestCellName.TOTAL_PRICE
          }
          noWrap
        />
      )}

      {!isCustomer && (
        <BlockCell data={company || '-'} title={ERequestCellName.COMPANY} />
      )}
    </>
  );
};

export default RequestModalDetails;
