import React, {ReactNode, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {DateTime} from 'luxon';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';

import iconMap from 'constants/iconMap';
import LocalStorageService from 'infra/common/localStorage.service';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {Routes} from 'constants/routes';
import {setMultiSearchTimeData} from 'store/search/searchSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IGroupDetails,
  IOfferDay,
  IRequestDay,
  TOfferDetails,
} from 'types/offer';
import {TSearchCriteria} from 'types/search';
import {useShowAlternatives} from '../../hooks/useShowAlternatives';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {
  NW2Gray100Color,
  NW2Gray600Color,
  NW2Green50Color,
  NW2Info500Color,
  NW2Info50Color,
  NW2Primary,
  NW2SuccessLight500Color,
} from 'constants/styleVars';
import {
  Actions,
  AdditionalMessage,
  Divider,
  Heading,
  IconBox,
  Info,
  StyledDiv,
  SubTitle,
  TextTitle,
  Title,
  Wrapper,
} from './OfferRequestStatus.styles';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

type TDataObject = {
  background: string;
  borderColor: string;
  icon: keyof typeof iconMap;
  iconColor?: string;
  title: string;
  subTitle: string | ReactNode;
  textTitle: string | null;
  text: React.ReactNode;
  expirationText?: string | ReactNode;
};

type TStatusText = {
  [key in Partial<EGroupStatus> | ERequestStatus | EOfferStatus]: TDataObject;
};

const getStatusText = (
  isGroupActive?: boolean,
  declinedAt?: string,
  expirationTime?: string,
): TStatusText => {
  const cancelled = {
    background: NW2Gray100Color,
    borderColor: NW2Gray600Color,
    icon: 'DECLINE',
    iconColor: NW2Primary,
    title: 'request cancelled',
    subTitle:
      'This request has been cancelled. You should have received a cancellation confirmation email.',
    textTitle: '',
    text: '',
  } as TDataObject;

  const expired = {
    background: NW2Gray100Color,
    borderColor: NW2Gray600Color,
    icon: 'DECLINE',
    iconColor: '#ff6e64',
    title: 'request declined',
    subTitle: 'The venue was unable to accommodate this booking request.',
    textTitle: '',
    text: `We are sorry, but the venue did not respond to your request.
   As such, your booking was cancelled and you were not charged.`,
  } as TDataObject;

  const pending = {
    background: NW2Info50Color,
    borderColor: NW2Info500Color,
    icon: 'COMMENT',
    title: 'Request sent',
    subTitle: `Your booking request has been sent, you will shortly receive a
              confirmation email.`,
    textTitle: 'What happens next?',
    text: `If the venue is able to accommodate your request, they will create an
      offer containing the room details and pricing. The offer will be shared
      with you as soon as it is created.`,
    expirationText: (
      <>
        You can expect to receive a response from the venue no later than{' '}
        <b>{expirationTime}</b>.{' '}
      </>
    ),
  } as TDataObject;

  return {
    [EGroupStatus.GROUP_REQUEST_PENDING]: pending,
    [EGroupStatus.GROUP_OFFER_PENDING]: pending,
    [ERequestStatus.REQUEST_PENDING]: pending,
    [ERequestStatus.REQUEST_DECLINED]: {
      background: NW2Gray100Color,
      borderColor: NW2Gray600Color,
      icon: 'DECLINE',
      iconColor: '#ff6e64',
      title: 'request declined',
      subTitle: 'The venue was unable to accommodate this booking request.',
      textTitle: isGroupActive ? 'What happens next?' : null,
      text: isGroupActive
        ? `Although this venues was not able to accommodate your request you still have options. 
        Hit “Compare venues” below and see what is still available`
        : null,
    },
    [EGroupStatus.GROUP_CANCELLED]: cancelled,
    [ERequestStatus.REQUEST_CANCELED]: cancelled,
    [EGroupStatus.GROUP_EXPIRED]: expired,
    [ERequestStatus.REQUEST_EXPIRED]: expired,
    [EOfferStatus.OFFER_PENDING]: {
      background: NW2Gray100Color,
      borderColor: NW2Gray600Color,
      icon: 'NW2_WORLD',
      title: 'request expired',
      subTitle: 'The venue was not able to respond to this request.',
      textTitle: '',
      text: `We are sorry, but the venue did not respond to your request.
     As such, your booking was cancelled and you were not charged.`,
    },
    [EOfferStatus.OFFER_DECLINED]: {
      background: NW2Gray100Color,
      borderColor: NW2Gray600Color,
      icon: 'DECLINE',
      iconColor: '#ff6e64',
      title: 'Offer declined',
      subTitle: declinedAt ? (
        <>You have declined this offer on {declinedAt}</>
      ) : (
        ''
      ),
      textTitle: isGroupActive ? 'What happens next?' : null,
      text: isGroupActive
        ? `Although this offer was not what you were looking for you still 
      have options. Hit “Compare venues” below and see what is still available`
        : null,
    },
    [EOfferStatus.OFFER_CONFIRMED]: {
      background: NW2Green50Color,
      borderColor: NW2SuccessLight500Color,
      icon: 'CHECKED',
      title: 'booking confirmed',
      subTitle:
        'Your booking is now confirmed. You should have received a confirmation email.',
      textTitle: '',
      text: '',
    },
    // hide on Customer side
    [EOfferStatus.OFFER_ACCEPTING_EXPIRED]: {
      background: NW2Gray100Color,
      borderColor: NW2Gray600Color,
      icon: 'NW2_WORLD',
      title: 'Offer expired',
      subTitle: 'You did not decline or confirm this offer in time.',
      // TODO: recomment with implementation of logic for button Request to book
      // textTitle: 'Changed your mind?',
      // text: 'Although this offer has now expired you can re-request it. Please note, the venue will have to re-confirm if they are able to accommodate.',
      textTitle: '',
      text: '',
    },
    [EGroupStatus.GROUP_PROCESSED]: {} as any,
  };
};

interface IProps {
  offerDetails: TOfferDetails;
  searchCriteria: TSearchCriteria;
  searchString: string;
  latitude: string;
  longitude: string;
  isGroupRequestPage?: boolean;
  groupStatus: EGroupStatus;
  venueZone?: string;
  isOffer?: boolean;
}

export function OfferRequestStatus({
  offerDetails,
  searchCriteria,
  searchString,
  latitude,
  longitude,
  venueZone,
  isOffer,
  isGroupRequestPage,
  groupStatus,
}: IProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAgentRole =
    useAppSelector(({app}) => app.user.role) === EUserRoleCognito.ROLE_AGENT;
  const groupDetails = useAppSelector(
    ({offers}) => offers.groupDetails || ({} as IGroupDetails),
  );

  const {
    expirationDate,
    declinedMessage,
    declinedReason = 'Meeting room(s) not available',
    number,
    groupNumber,
    days,
    resolvedAt,
    resolverName,
    status,
  } = offerDetails;

  const meetingRoomCapacity =
    getFilteredUnitsByEventType(days)[0].items?.[0]?.participants;

  const maxBedroomsCapacity = (days as (IRequestDay | IOfferDay)[]).reduce(
    (maxAcc, {items, bedrooms}) => {
      if (!items?.length) {
        const totalBedrooms = bedrooms.reduce(
          (bedroomsAcc, {quantity}) => bedroomsAcc + quantity,
          0,
        );
        return Math.max(maxAcc, totalBedrooms);
      }

      return maxAcc;
    },
    0,
  );

  const {meetingRequestData, querySearchData, requestedTimeData} =
    useShowAlternatives({
      searchCriteria,
      latitude,
      longitude,
      meetingRoomCapacity:
        (meetingRoomCapacity as number) || maxBedroomsCapacity,
      searchString,
    });

  const declinedAt = DateTime.fromISO(resolvedAt).toFormat(
    DateFormats["22/05/1997 'at' 22:22 (+02:00)"],
  );
  const expirationTime = DateUtils.getFormattedDayMonthTime({
    date: expirationDate,
    venueZone,
  });

  const groupItems = [...groupDetails.requests, ...groupDetails.offers];
  const isSingleGroupDeclined =
    /**
     * we should show request declined status when group consists only 1 request
     * or offer & status of request item is following
     */
    groupItems.length === 1 &&
    [
      ERequestStatus.REQUEST_EXPIRED,
      ERequestStatus.REQUEST_DECLINED,
      EOfferStatus.OFFER_ACCEPTING_EXPIRED,
    ].includes(groupItems[0]?.status);

  const groupOrRequestStatus = isSingleGroupDeclined
    ? ERequestStatus.REQUEST_DECLINED
    : isGroupRequestPage
    ? groupStatus
    : status;

  const isGroupActive = [
    EGroupStatus.GROUP_OFFER_PENDING,
    EGroupStatus.GROUP_REQUEST_PENDING,
  ].includes(groupStatus as EGroupStatus);

  const isShowAlternatives = isGroupRequestPage
    ? isGroupActive
    : (groupOrRequestStatus === ERequestStatus.REQUEST_PENDING ||
        groupOrRequestStatus === ERequestStatus.REQUEST_DECLINED ||
        groupOrRequestStatus === EOfferStatus.OFFER_DECLINED ||
        groupOrRequestStatus === ERequestStatus.REQUEST_EXPIRED) &&
      isGroupActive;

  const {
    background = NW2Info50Color,
    borderColor = NW2Info500Color,
    icon,
    iconColor,
    title,
    subTitle,
    textTitle,
    text,
    expirationText,
  } = getStatusText(isGroupActive, declinedAt, expirationTime)[
    groupOrRequestStatus
  ];

  const onShowAlternatives = useCallback(() => {
    LocalStorageService.setByKey(
      'multiSearchData',
      JSON.stringify({
        meetingRequestData,
        timeData: requestedTimeData,
      }),
    );
    dispatch(setMultiSearchTimeData(requestedTimeData));
    navigate({
      pathname: Routes.venuesList,
      search: queryString.stringify({
        ...querySearchData,
        isMultiSearchOpen: true,
      }),
    });
  }, [
    dispatch,
    meetingRequestData,
    navigate,
    querySearchData,
    requestedTimeData,
  ]);

  const onCompareVenues = useCallback(() => {
    navigate({
      pathname: `/compare-overview/${offerDetails.groupId}`,
    });
  }, [offerDetails.groupId, navigate]);

  return (
    <Wrapper background={background} borderColor={borderColor}>
      <Heading>
        <IconBox color={iconColor}>
          <Icon icon={icon as string} />
        </IconBox>

        <div>
          <Title>{title}</Title>
          <SubTitle>
            <div>{subTitle}</div>
            <div>
              {isOffer ? 'Offer ID' : 'Request ID'}:{' '}
              <b>#{isGroupRequestPage ? groupNumber : number}</b>
            </div>
            {groupOrRequestStatus === EOfferStatus.OFFER_CONFIRMED &&
              resolverName && (
                <div>
                  Offer made by: <b>{resolverName}</b>
                </div>
              )}
          </SubTitle>
        </div>
      </Heading>

      {/* TODO: remove EOfferStatus.OFFER_ACCEPTING_EXPIRED with implementation of logic for button Request to book */}
      <Divider
        isVisible={
          groupOrRequestStatus === ERequestStatus.REQUEST_DECLINED ||
          groupOrRequestStatus === ERequestStatus.REQUEST_EXPIRED ||
          !!textTitle
        }
      />

      {groupOrRequestStatus === ERequestStatus.REQUEST_DECLINED && (
        <Info isMarginBottom={!!textTitle}>
          <div>
            <b>Reason: </b> {declinedReason}
          </div>
          {declinedMessage && (
            <div>
              <div>
                <b>Additional message:</b>
              </div>
              <AdditionalMessage>{declinedMessage}</AdditionalMessage>
            </div>
          )}
        </Info>
      )}

      {textTitle && <TextTitle>{textTitle}</TextTitle>}
      {text && <div>{text}</div>}
      {expirationText && <StyledDiv>{expirationText}</StyledDiv>}

      {groupOrRequestStatus === ERequestStatus.REQUEST_EXPIRED &&
        !isGroupActive &&
        !isAgentRole && (
          <Actions>
            <NW2Button buttonType='primary' onClick={onShowAlternatives}>
              Show alternatives
            </NW2Button>
          </Actions>
        )}

      {isShowAlternatives && (
        <Actions>
          <NW2Button buttonType='primary' onClick={onCompareVenues}>
            Compare venues
          </NW2Button>
        </Actions>
      )}

      {/* TODO: recomment with implementation of logic for button Request to book */}
      {/* {status === EOfferStatus.OFFER_ACCEPTING_EXPIRED && (
        <Actions>
          <NW2Button buttonType='primary'>request to book</NW2Button>
        </Actions>
      )} */}
    </Wrapper>
  );
}
