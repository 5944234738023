import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {EEventType} from 'types/venue';
import {getCurrencySymbol} from 'utils/stringUtils';
import {
  getFilteredExtrasByType,
  getSeatingPlanByParticipants,
} from 'utils/venueUtils';
import {IRequestMeeting} from '../types';

interface IProps {
  activeUnitId: string;
  requestUnits: IRequestMeeting[];
}
export const useOfferCreateData = ({activeUnitId, requestUnits}: IProps) => {
  const allExtrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const currencyKey = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const currency = getCurrencySymbol(currencyKey);

  const requestedUnitDetails = requestUnits.find(
    ({id}) => id === activeUnitId,
  ) as IRequestMeeting;

  const activeUnitBedroomExtras = useMemo(
    () =>
      getFilteredExtrasByType(
        requestedUnitDetails?.bedrooms || [],
        EResourcesType.BEDROOM,
        allExtrasOption,
      ),
    [allExtrasOption, requestedUnitDetails?.bedrooms],
  );

  const isPreOrPostEvent = [
    EEventType.PRE_ARRIVAL,
    EEventType.POST_EVENT,
  ].includes(requestedUnitDetails?.eventType);

  const isCurrentDayWithoutMeetings =
    requestedUnitDetails?.eventType === EEventType.DAY &&
    requestedUnitDetails?.items &&
    !requestedUnitDetails.items.length;

  const requestedCapacity = requestedUnitDetails?.participants || 0;
  const requestedSeatingPlan =
    requestedUnitDetails?.setupStyle ||
    getSeatingPlanByParticipants(requestedCapacity);

  return {
    activeUnitBedroomExtras,
    currency,
    isPreOrPostEvent,
    isCurrentDayWithoutMeetings,
    requestedUnitDetails,
    requestedCapacity,
    requestedSeatingPlan,
  };
};
