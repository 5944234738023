import React, {useMemo} from 'react';
import {Drawer} from 'antd';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';

import {EMPTY_OBJECT} from 'constants/app';
import {
  drawerZIndex,
  offsetDef,
  offsetXSm,
  offsetLg,
  borderRadiusXLg,
  blackColor,
} from 'constants/styleVars';
import {DrawerProps} from 'antd/lib/drawer';

const StyledDrawer = styled(Drawer)`
  z-index: ${drawerZIndex};

  .ant-drawer-header-title {
    flex-direction: row-reverse;
    overflow: hidden;
  }
  // need this for share drawer
  .ant-drawer-title {
    overflow: hidden;
  }
  .ant-drawer-close {
    margin-left: 0;
    margin-right: -${offsetXSm};
    padding: 0 ${offsetXSm};
  }

  .ant-drawer-header-close-only {
    position: absolute;
    top: ${offsetLg};
    right: 0;
    padding-top: 0;
    z-index: ${drawerZIndex};
    border-bottom: none;
  }

  .ant-drawer-content {
    border-radius: ${borderRadiusXLg} ${borderRadiusXLg} 0 0;
  }
`;

const IconBox = styled.div`
  color: ${blackColor};
`;

interface IProps extends DrawerProps {
  padding?: string;
}
const StyledAntdDrawer = (props: IProps) => {
  const navbarHeight = useAppSelector(({app}) => app.navbarHeight);

  const paddingBottom = useMemo(() => {
    return `${props.padding || navbarHeight + parseInt(offsetDef)}px`;
  }, [navbarHeight, props.padding]);

  const defaultProps = {
    width: '100%',
    height: '100%',
    placement: 'bottom' as const,
    destroyOnClose: true,
    closeIcon: (
      <IconBox>
        <Icon icon='NW2_CLOSE' />
      </IconBox>
    ),
  };

  const bodyStyle = {
    ...(props.bodyStyle || EMPTY_OBJECT),
    ...(navbarHeight ? {paddingBottom} : EMPTY_OBJECT),
  };

  const propsToUse = {
    ...defaultProps,
    ...props,
    bodyStyle,
  };

  return <StyledDrawer {...propsToUse} />;
};

export default StyledAntdDrawer;
