import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import AccommodationTitle from 'view/venue/components/AccommodationTitle';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';

import {EResourcesCode, IExtrasOption} from 'types/dto/IExtras.type';
import {pluralize} from 'utils/helpers';
import DateUtils from 'utils/dateUtils';
import {ERoomSchema} from 'types/venue';
import {useAppSelector} from 'store/hooks';
import {
  UnitTitle,
  UnitInfo,
  UnitName,
  ExtrasTitle,
  Container,
  Wrapper,
  Header,
  TitleContainer,
  Title,
  DefaultUnitBox,
} from './Space.styles';
import {capitalizeText} from 'utils/stringUtils';
import {IPreviewExtra} from 'store/venues/types';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';
import {TSearchCriteriaExtra, TSearchCriteriaRoom} from 'types/search';
import {OfferUnitImage} from 'view/venue/Offer/components/OfferUnitImage/OfferUnitImage';
import {
  mdBp,
  NW2Gray100Color,
  NW2Gray800Color,
  offsetLg,
} from 'constants/styleVars';

interface IItemModel {
  title: ReactNode;
  content: ReactNode;
  date?: ReactNode;
}

type TProps = {
  bedrooms: TSearchCriteriaExtra[];
  foodAndBeverage: TSearchCriteriaExtra[];
  units: TSearchCriteriaRoom[];
  hasDots?: boolean;
};

export const DefaultRequestSpace = ({
  units,
  foodAndBeverage,
  bedrooms,
  hasDots,
}: TProps) => {
  const viewportWidth = useAppSelector((state) =>
    _get(state, 'app.deviceType.width'),
  );

  const spaceImgHeight = viewportWidth <= mdBp ? '164px' : '106px';
  const spaceImgPlaceholderWidth = viewportWidth <= mdBp ? '152px' : '98px';

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {getHoursAndMinutes} = DateUtils;

  const baseAccordionData = units.map((unit, index, array) => {
    const {setupStyle, capacity, unitId, checkIn, checkOut, extras} = unit;

    const equipmentExtras = moveToEndWhere(
      // move wi-fi at the end
      [...extras],
      'code',
      EResourcesCode.WIFI,
    );

    const timeText = `${getHoursAndMinutes(checkIn)} - ${getHoursAndMinutes(
      checkOut,
    )}`;

    const roomSetupText = setupStyle ? ERoomSchema[setupStyle] : '';

    const participantsText = `${pluralize('people', capacity)}`;

    const roomCounter = array.length > 1 ? String(index + 1) : '';

    const unitName = `${ERoomSchema[setupStyle]} Meeting Room`;

    const unitTitle = `Meeting room ${roomCounter}`;

    return {
      title: (
        <div key={`head_${unitId}`}>
          <UnitTitle>{capitalizeText(unitTitle)}</UnitTitle>
        </div>
      ),
      content: (
        <>
          <DefaultUnitBox>
            <OfferUnitImage
              iconColor={NW2Gray800Color}
              bgColor={NW2Gray100Color}
              iconWidth={spaceImgPlaceholderWidth}
              boxHeight={spaceImgHeight}
              spacing={`${offsetLg} 0`}
            />

            <div>
              <UnitName>{unitName}</UnitName>

              <UnitInfo>
                <span>Time:&nbsp;</span>
                {timeText}
              </UnitInfo>

              {!!roomSetupText && (
                <UnitInfo>
                  <span>Room setup:&nbsp;</span>
                  {roomSetupText}
                </UnitInfo>
              )}
              <UnitInfo>
                <span>Participants:&nbsp;</span>
                {participantsText}
              </UnitInfo>
            </div>
          </DefaultUnitBox>

          {!!equipmentExtras.length && (
            <div>
              {equipmentExtras.map(({code, extraId}, idx) => (
                <span key={extraId}>
                  {!!idx && <> · </>}
                  {CODE_TO_EXTRA_NAME[code as EResourcesCode]}
                </span>
              ))}
            </div>
          )}
        </>
      ),
      expandable: !!equipmentExtras.length,
    };
  });

  const foodBeverageData = {
    title: <ExtrasTitle>Food & Beverage</ExtrasTitle>,
    content: (
      <PreviewExtrasList
        extras={foodAndBeverage}
        extrasOptions={extrasOption}
        hasDots={hasDots}
      />
    ),
  };

  const sortedBedrooms = _orderBy(bedrooms, ['code'], ['desc']);

  const accommodationData = {
    title: <AccommodationTitle checkIn={units[0].checkIn} />,
    content: (
      <PreviewExtrasList
        extras={sortedBedrooms as unknown as IPreviewExtra[]}
        extrasOptions={extrasOption}
        hasDots={hasDots}
      />
    ),
  };

  const items = foodAndBeverage.length
    ? [...baseAccordionData, foodBeverageData]
    : baseAccordionData;

  const itemsToRender: IItemModel[] = sortedBedrooms.length
    ? [...items, accommodationData]
    : items;

  return (
    <Wrapper>
      {itemsToRender.map((item, idx) => (
        <Container key={'space-preview-item' + idx}>
          <Header>
            <TitleContainer>
              <Title>{item.title}</Title>
            </TitleContainer>
          </Header>
          {item.content}
        </Container>
      ))}
    </Wrapper>
  );
};
