import React, {memo} from 'react';

import AccommodationTitle from 'view/venue/components/AccommodationTitle';

import {RequestUnitExtras} from '../OfferRequestDetails/RequestUnitExtras';

import {getFilteredExtrasByType} from 'utils/venueUtils';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {IOfferUnitExtra} from 'types/offer';

interface IProps {
  checkIn: string;
  unitExtras: IOfferUnitExtra[];
  extrasOption?: IExtrasOption[];
  currencySymbol?: string;
  isOffer?: boolean;
  isRequestWithUnitId?: boolean;
}

export const AccommodationExtras = memo(
  ({
    checkIn,
    unitExtras,
    extrasOption = [],
    currencySymbol,
    isOffer,
    isRequestWithUnitId,
  }: IProps) => {
    const filteredBedrooms = getFilteredExtrasByType(
      unitExtras,
      EResourcesType.BEDROOM,
      extrasOption,
    );

    if (!filteredBedrooms.length) return null;

    return (
      <>
        <AccommodationTitle checkIn={checkIn} />
        <RequestUnitExtras
          data={filteredBedrooms}
          isOffer={isOffer}
          currencySymbol={currencySymbol}
          isRequestWithUnitId={isRequestWithUnitId}
          isBedroom
        />
      </>
    );
  },
);
