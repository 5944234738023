import React, {useMemo} from 'react';

import DateRangeString from 'view/components/DateRangeString';
import NW2InfoCard from 'view/common/NW2InfoCard';
import StatusLabel from 'view/common/StatusLabel';

import {getMinMaxDates} from '../helpers';

import {IBookingDetails} from 'types/bookingOrders';
import {
  Block,
  InlineBlock,
  Line,
  Link,
  StyledLink,
  SummaryText,
  SummaryTitle,
} from '../BookingDetails.styles';
import {EBookingStatus} from 'types/booking';
import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';
import {NOT_PROVIDED_BY_BOOKER} from 'constants/app';

type TProps = {
  bookingDetails: IBookingDetails;
  dates: string[];
  totalPriceAndCurrency: string;
  isRequestStatus: boolean;
};

const NOT_PROVIDED = <i>{NOT_PROVIDED_BY_BOOKER}</i>;

const SummaryBookingInfo = ({
  bookingDetails,
  dates,
  totalPriceAndCurrency,
  isRequestStatus,
}: TProps) => {
  const {
    orderNumber,
    eventName,
    billingAddressDto,
    firstName,
    lastName,
    phone,
    email,
    bookingStatus,
    isRfp,
    holdUp,
  } = bookingDetails;

  const hoursOrMinutes = useMemo(() => {
    const splitTime = String(holdUp).split(':');
    return Number(splitTime[0])
      ? `${splitTime[0]}h`
      : `${Number(splitTime[1])}m`;
  }, [holdUp]);

  const {min, max} = getMinMaxDates(dates);

  return (
    <>
      {bookingStatus === EBookingStatus.RFP_PENDING && (
        <Block>
          <NW2InfoCard
            title='Why can’t I see who is requesting this?'
            type={EInfoCardType.info}
          >
            Booker’s name and contact information will become available as soon
            as, and only if, this request is confirmed.
          </NW2InfoCard>
        </Block>
      )}
      {bookingStatus === EBookingStatus.RFP_PENDING && (
        <Block>
          <SummaryTitle>Received</SummaryTitle>
          <SummaryText>
            <span>{`${hoursOrMinutes} ago`}</span>
          </SummaryText>
        </Block>
      )}
      <Block>
        <SummaryTitle>
          {isRfp && bookingStatus !== EBookingStatus.CONFIRMED
            ? 'Request ID'
            : 'Booking ID'}
        </SummaryTitle>
        <SummaryText>
          <span>{orderNumber}</span>
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>Date</SummaryTitle>
        <SummaryText>
          <DateRangeString checkIn={min} checkOut={max} />
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>Meeting name</SummaryTitle>
        <SummaryText>
          <span>{eventName || NOT_PROVIDED}</span>
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>Company name</SummaryTitle>
        <SummaryText>
          <span>{billingAddressDto?.companyName || NOT_PROVIDED}</span>
        </SummaryText>
      </Block>
      {!isRequestStatus && (
        <Block>
          <SummaryTitle>Booker</SummaryTitle>
          <SummaryText>
            {firstName} {lastName}
          </SummaryText>
        </Block>
      )}
      <Block>
        <SummaryTitle>
          {isRfp && bookingStatus !== EBookingStatus.CONFIRMED
            ? 'Total request price'
            : 'Booking total'}
        </SummaryTitle>
        <SummaryText>{totalPriceAndCurrency}</SummaryText>
      </Block>
      {bookingStatus !== EBookingStatus.RFP_PENDING && (
        <Block>
          <SummaryTitle>Status</SummaryTitle>
          <InlineBlock>
            <StatusLabel status={bookingStatus} />
          </InlineBlock>
        </Block>
      )}
      {!isRequestStatus && (
        <>
          <Line />
          <Block>
            <SummaryTitle>Booker’s contact information</SummaryTitle>
            <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Block>
        </>
      )}
    </>
  );
};

export default SummaryBookingInfo;
