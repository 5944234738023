import React, {useCallback, useEffect, useState} from 'react';

import {DropdownList} from './DropdownList';
import {useClickOutside} from 'hooks/useClickOutside';

import {
  IDropdownListOption,
  ISelectProps,
} from 'view/components/NW2FormItem/types';
import {
  StyledInputContainer,
  StyledLabel,
  StyledLabelContainer,
} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {Placeholder, StyledPlaceholderIcon} from './Select.styles';

interface IProps extends ISelectProps {
  onItemChange: (value: string) => void;
}

function Select({
  label,
  name,
  options,
  placeholder,
  hasError,
  inputProps,
  readonly,
  onItemChange,
}: IProps) {
  const [focused, setFocused] = useState(false);
  const [optionsList, setOptionsList] = useState<IDropdownListOption[]>([]);

  const filterOptionsList = (value: string) => {
    if (!options) return;
    const filteredList = options.filter((item) =>
      item.value.includes(value.toLowerCase().trim()),
    );
    setOptionsList(filteredList);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    inputProps.onChange(event);
    if (!inputProps.multiple || !readonly)
      filterOptionsList(event.target.value);
  };
  const onClickHandler = () => setFocused(!focused);
  const onBlurHandler = useCallback(() => setFocused(false), []);

  const [selectContainer] = useClickOutside(focused, onBlurHandler);

  const placeholderText = focused ? '' : placeholder;

  useEffect(() => {
    if (options) setOptionsList(options);
  }, [options]);

  return (
    <StyledInputContainer ref={selectContainer}>
      {label && (!!inputProps.value || focused) && (
        <StyledLabelContainer hasError={hasError} focused={focused}>
          <StyledLabel>{label}</StyledLabel>
        </StyledLabelContainer>
      )}

      <Placeholder
        {...inputProps}
        name={name}
        placeholder={placeholderText}
        onClick={onClickHandler}
        onChange={onChangeHandler}
        value={inputProps.value}
        role='presentation'
        autoComplete='off'
        readOnly={readonly}
        isMultiple={false}
        hasError={hasError}
        focused={focused}
      />

      <StyledPlaceholderIcon
        icon='ARROW_UP_FILLED'
        iconPosition='right'
        isFocused={focused}
        transparent
      />

      <DropdownList
        optionsList={optionsList}
        focused={focused}
        inputValue={inputProps.value}
        onBlurHandler={onBlurHandler}
        onItemChange={onItemChange}
      />
    </StyledInputContainer>
  );
}

export default Select;
