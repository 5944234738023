import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';
import SummaryBookingInfo from './components/SummaryBookingInfo';
import BookingDetailsInfo from './components/BookingDetailsInfo';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import NW2Button from 'view/components/NW2Button';
import BookingChangesStatusModal from '../../BookingChangeOverviewSidebar/components/BookingChangesStatusModal';
import BookingChangeOverviewSidebar from '../../BookingChangeOverviewSidebar/BookingChangeOverviewSidebar';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import useToggle from 'hooks/useToggle';

import {EOrderShowStatus} from 'types/bookingOrders';
import {
  FooterTotalPrice,
  FooterTotalSubTitle,
  FooterTotalTitle,
  FooterWrapper,
} from './BookingDetails.styles';
import {offsetDef} from 'styles/configs/offset';
import {EBookingStatus} from 'types/booking';
import {patchOrderShowStatus} from 'store/bookingsSupplier/actions';
import {TFetchSupplierOrders} from 'view/supplier/Bookings/BookingListRooms/BookingsListRooms';
import {convertToObject} from 'view/supplier/BookingChangeOverviewSidebar/helpers';

interface IProps {
  venueTimeZone: string;
  onCloseOrderDetails: () => void;
  getOrders: (props: TFetchSupplierOrders) => void;
  onShowConfirmModal: (orderId: string) => (e?: SyntheticEvent) => void;
  onShowDeclineModal: (orderId: string) => (e?: SyntheticEvent) => void;
  isRTC: boolean;
}

const BookingDetails = ({
  venueTimeZone,
  onCloseOrderDetails,
  getOrders,
  onShowConfirmModal,
  onShowDeclineModal,
  isRTC,
}: IProps) => {
  const dispatch = useAppDispatch();

  const bookingDetailsByOrderId = useAppSelector(
    (state) => state.bookingsSupplier.bookingDetails,
  );
  const bookingChanges = useAppSelector(
    (state) => state.bookingsSupplier.bookingChanges,
  );

  // convert array to object where key is bookingStatus
  const bookingChangesObj = convertToObject(bookingChanges, 'bookingStatus');

  const bookingDetailsFromChangesPending =
    bookingChangesObj[EBookingStatus.RTC_PENDING];

  const bookingDetailsFromChangesConfirmed =
    bookingChangesObj[EBookingStatus.CONFIRMED];

  const bookingDetails = isRTC
    ? bookingDetailsFromChangesPending || bookingDetailsFromChangesConfirmed
    : bookingDetailsByOrderId;

  const bookingStatus = bookingDetails?.bookingStatus;
  const isRTCPendingStatus = bookingStatus === EBookingStatus.RTC_PENDING;
  const [isBookingChangeSidebarVisible, handleBookingChangeSidebarVisibility] =
    useToggle();
  const [isChangesStatusModal, handleChangesStatusModal] = useState(isRTC);

  useEffect(() => {
    if (isRTCPendingStatus) {
      handleBookingChangeSidebarVisibility();
    }
  }, [isRTCPendingStatus, handleBookingChangeSidebarVisibility]);

  // collecting dates from orderDays
  const dates: string[] = useMemo(() => {
    const orderDays = bookingDetails?.orderDays;
    if (!orderDays) return [];

    const result: string[] = [];

    orderDays.forEach((item) => {
      result.push(item.checkInDate, item.checkOutDate);
    });

    return result;
  }, [bookingDetails?.orderDays]);

  if (!bookingDetails || !bookingStatus) return null;

  const {
    orderId,
    currency,
    billingAddressDto,
    isRfp,
    resolvedAt,
    createdAt,
    totalPrice,
    holdUp,
    declinedReason,
    declinedMessage,
    showStatus,
    orderNumber,
    nextOrderVersion,
    previousOrderVersion,
    updatedAt,
    orderDays,
  } = bookingDetails;

  const isEdited = !!(nextOrderVersion || previousOrderVersion);
  const prevBookingStatus = bookingChanges.find((item) =>
    bookingStatus === EBookingStatus.CONFIRMED && nextOrderVersion
      ? item.orderId === nextOrderVersion
      : item.orderId === previousOrderVersion,
  )?.bookingStatus;

  // Currency
  const currencySymbol = getSymbolFromCurrency(currency);
  const totalPriceAndCurrency = totalPrice
    ? `${formatFloatWithOneNumber(totalPrice)} ${currencySymbol}`
    : `0,00 ${currencySymbol}`;

  const isRequestStatus = [
    EBookingStatus.RFP_CANCELLED,
    EBookingStatus.RFP_DECLINED,
    EBookingStatus.RFP_EXPIRED,
    EBookingStatus.RFP_PENDING,
  ].includes(bookingStatus);

  const isUnreadShowStatus = showStatus === EOrderShowStatus.UNREAD;

  const onCloseHandler = () => {
    if (orderId && isUnreadShowStatus) {
      const callback = () => getOrders({});
      dispatch(patchOrderShowStatus(orderId, callback));
    }
    if (isBookingChangeSidebarVisible) handleBookingChangeSidebarVisibility();
  };

  return (
    <>
      <BookingChangesStatusModal
        isShowModal={isChangesStatusModal}
        handleChangesStatusModal={handleChangesStatusModal}
        bookingStatus={prevBookingStatus}
        updatedAt={updatedAt}
        isRTCinSearchParam={isRTC}
      />
      <BookingChangeOverviewSidebar
        isShowModal={isBookingChangeSidebarVisible || isUnreadShowStatus}
        bookingChanges={bookingChanges}
        currencySymbol={currencySymbol}
        onCloseHandler={onCloseHandler}
        orderNumber={orderNumber}
        handleChangesStatusModal={handleChangesStatusModal}
        bookingChangesObj={bookingChangesObj}
      />
      <NW2ModalTwoColumns
        title={
          isRfp && bookingStatus !== EBookingStatus.CONFIRMED
            ? 'request summary'
            : 'booking summary'
        }
        isShowed
        onToggleShow={onCloseOrderDetails}
        colLeft={
          <SummaryBookingInfo
            bookingDetails={bookingDetails}
            dates={dates}
            totalPriceAndCurrency={totalPriceAndCurrency}
            isRequestStatus={isRequestStatus}
          />
        }
        colRight={
          <BookingDetailsInfo
            billingAddressDto={billingAddressDto}
            isRfp={isRfp}
            resolvedAt={resolvedAt}
            createdAt={createdAt}
            currencySymbol={currencySymbol}
            totalPriceAndCurrency={totalPriceAndCurrency}
            bookingStatus={bookingStatus}
            declinedReason={declinedReason}
            holdUp={holdUp}
            declinedMessage={declinedMessage}
            venueTimeZone={venueTimeZone}
            isEdited={isEdited}
            handleBookingChangeSidebarVisibility={
              handleBookingChangeSidebarVisibility
            }
            prevBookingStatus={prevBookingStatus}
            isRTCPendingStatus={isRTCPendingStatus}
            updatedAt={updatedAt}
            bookingChangesObj={bookingChangesObj}
            orderDays={orderDays}
            orderNumber={orderNumber}
          />
        }
        footer={
          ((isRfp && bookingStatus === EBookingStatus.RFP_PENDING) ||
            isRTCPendingStatus) && (
            <FooterWrapper withPrice={!!totalPrice}>
              <div>
                <FooterTotalTitle>
                  {isRTCPendingStatus ? 'New total' : 'Total request price'}
                </FooterTotalTitle>
                <FooterTotalSubTitle>
                  Includes taxes and fees
                </FooterTotalSubTitle>
              </div>

              <FooterTotalPrice>{totalPriceAndCurrency}</FooterTotalPrice>

              {isRTCPendingStatus ? (
                <NW2Button
                  buttonType='primary'
                  onClick={handleBookingChangeSidebarVisibility}
                >
                  View & Confirm changes
                </NW2Button>
              ) : (
                <div>
                  <NMMSubmitSection
                    submitLabel='confirm'
                    cancelLabel='decline'
                    handleSubmit={onShowConfirmModal(orderId)}
                    handleCancel={onShowDeclineModal(orderId)}
                    isLoading={false}
                    gap={offsetDef}
                    size='medium'
                    margin='0'
                  />
                </div>
              )}
            </FooterWrapper>
          )
        }
      />
    </>
  );
};

export default BookingDetails;
