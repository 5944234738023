import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

import AccommodationTitle from 'view/venue/components/AccommodationTitle';
import TruncateText from 'view/components/TruncateText';
import ExtrasList from './components/ExtrasList';

import {SpaceImage} from './components/SpaceImage';

// import {setSpaceDetailsId} from 'store/venues/actions';
import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {filterPreviewExtrasByExtraType} from 'utils/venueUtils';
import {pluralize} from 'utils/helpers';
import DateUtils from 'utils/dateUtils';
import {ERoomSchema} from 'types/venue';
import {IExtraResponse, IUnitResponse} from 'types/dto/IPublicVenue';
import {
  IPreviewUnit,
  IPreviewUnitResponse,
  ISummaryExtra,
} from 'types/dto/IBooking.types';
import {
  UnitTitle,
  UnitBox,
  UnitInfo,
  UnitName,
  ExtrasTitle,
  Container,
  Wrapper,
  Header,
  TitleContainer,
  Title,
  EquipmentsBox,
} from './Space.styles';
import {convertOfferExtrasToVenueExtras} from 'view/venue/Offer/helpers';
import {capitalizeText} from 'utils/stringUtils';
import OfferExtrasList from 'view/venue/components/OfferExtrasList';
import {IOfferUnitExtra} from 'types/offer';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import {TSearchCriteriaExtra} from 'types/search';

type TUnit = IPreviewUnit | IPreviewUnitResponse;

type TProps = {
  units: TUnit[];
  checkIn: string;
  dayIndex: number;
  previewFoodAndBeverage?: (ISummaryExtra | IOfferUnitExtra)[];
  bedrooms?: (IOfferUnitExtra | TSearchCriteriaExtra | IExtraResponse)[];
  duration?: number;
  isAlternative?: boolean;
};

const initMaxToShowEquipment = 5;
const initMaxToShowFoodBeverage = 3;

const OfferReviewSpace = ({
  units,
  dayIndex = 0,
  previewFoodAndBeverage = [],
  bedrooms,
  duration,
  isAlternative,
  checkIn,
}: TProps) => {
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {getHoursAndMinutes} = DateUtils;

  const {equipmentList, foodBeverageList} = useMemo(() => {
    /**
     * we need to convert all common extras to IExtrasResponse for using them by ExtrasList
     * in the offer request flow
     */
    const extrasOptionMap = convertOfferExtrasToVenueExtras(extrasOption);

    return {
      equipmentList: filterPreviewExtrasByExtraType<IExtrasResponse>(
        extrasOptionMap,
        EResourcesType.EQUIPMENT,
      ),
      foodBeverageList: filterPreviewExtrasByExtraType<IExtrasResponse>(
        extrasOptionMap,
        EResourcesType.FOOD_AND_BEVERAGE,
      ),
    };
  }, [extrasOption]);

  const baseAccordionData = useMemo(() => {
    const isMultiRoom = units.length > 1;

    return units.map((unit, index) => {
      const {
        unitId = 0,
        checkInDate,
        checkOutDate,
        chosenSetupStyle,
        chosenExtras,
        // @ts-ignore
        unitInfo,
        // @ts-ignore
        maxParticipants,
      } = unit;

      const chosenEquipmentExtras = moveToEndWhere(
        // move wi-fi at the end
        (chosenExtras as IExtraResponse[]).filter(
          ({extraType}) => !extraType || extraType === EResourcesType.EQUIPMENT,
        ),
        'code',
        EResourcesCode.WIFI,
      );

      const timeText = `${getHoursAndMinutes(
        checkInDate,
      )} - ${getHoursAndMinutes(checkOutDate)}`;

      const seatingPlan =
        chosenSetupStyle || (unit as IUnitResponse).requestedSetupStyle;

      const roomSetupText = seatingPlan ? ERoomSchema[seatingPlan] : '';

      const maxCapacityText = maxParticipants ? `(${maxParticipants} max)` : '';

      const participantsText = `${pluralize(
        'people',
        // @ts-ignore
        (unit as IPreviewUnit).unitFilter?.participants || unit.participants,
      )} ${maxCapacityText ? maxCapacityText : ''}`;

      // const openSpaceDetails = () => { // todo fix unitInfo from BE side than uncomment
      //   dispatch(setSpaceDetailsId(unitId));
      // };

      const roomCounter = isMultiRoom ? (index + 1).toString() : '';

      const unitName = isAlternative ? (
        `${ERoomSchema[seatingPlan]} Meeting Room`
      ) : unitInfo?.unitName ? (
        <TruncateText text={unitInfo.unitName} hideToggler />
      ) : (
        'Meeting Room'
      );

      return {
        title: (
          <div key={`head_${unitId}`}>
            <UnitTitle>
              {capitalizeText(`Meeting room ${roomCounter}`)}
            </UnitTitle>
          </div>
        ),
        content: (
          <>
            <UnitBox>
              <SpaceImage images={unitInfo?.documents} />

              <div>
                <UnitName>{unitName}</UnitName>

                <UnitInfo>
                  <span>Time:&nbsp;</span>
                  {timeText}
                </UnitInfo>

                {!!roomSetupText && (
                  <UnitInfo>
                    <span>Room setup:&nbsp;</span>
                    {roomSetupText}
                  </UnitInfo>
                )}
                <UnitInfo>
                  <span>Participants:&nbsp;</span>
                  {participantsText}
                </UnitInfo>
              </div>
            </UnitBox>

            {!!equipmentList.length && (
              <EquipmentsBox>
                <ExtrasList
                  chosenExtras={chosenEquipmentExtras}
                  extras={equipmentList}
                  initMaxToShow={initMaxToShowEquipment}
                  dayId={dayIndex}
                  unitId={unitId}
                  isPriceHidden={false}
                  checkInDate={checkInDate}
                  checkOutDate={checkOutDate}
                  isPreview
                />
              </EquipmentsBox>
            )}
          </>
        ),
        expandable: !!equipmentList.length && !!chosenEquipmentExtras.length,
      };
    });
  }, [dayIndex, equipmentList, getHoursAndMinutes, isAlternative, units]);

  const itemsToRender = useMemo(() => {
    const meetingRoomParticipants = (units as TUnit[]).reduce(
      (prev, curr) =>
        Math.max(
          prev,
          (curr as IPreviewUnit).unitFilter?.capacity ||
            (curr as IPreviewUnitResponse)?.requestedCapacity ||
            0,
        ),
      0,
    );

    const foodBeverageData = {
      title: <ExtrasTitle>Food & Beverage</ExtrasTitle>,
      content: (
        <ExtrasList
          chosenExtras={previewFoodAndBeverage}
          extras={foodBeverageList}
          initMaxToShow={initMaxToShowFoodBeverage}
          dayId={dayIndex}
          participants={meetingRoomParticipants}
          duration={duration}
          isPriceHidden={false}
          isPreview
          isFoodAndBeverage
        />
      ),
    };

    const sortedBedrooms = _orderBy(bedrooms, ['code'], ['desc']);

    const accommodationData = {
      title: <AccommodationTitle checkIn={checkIn} noMargin />,
      content: <OfferExtrasList bedrooms={sortedBedrooms} />,
    };

    const hasFoodAndBeverage = previewFoodAndBeverage.length;

    const items = hasFoodAndBeverage
      ? [...baseAccordionData, foodBeverageData]
      : baseAccordionData;

    return sortedBedrooms?.length ? [...items, accommodationData] : items;
  }, [
    baseAccordionData,
    bedrooms,
    checkIn,
    dayIndex,
    duration,
    foodBeverageList,
    previewFoodAndBeverage,
    units,
  ]);

  return (
    <Wrapper>
      {itemsToRender.map(({title, content}, idx) => (
        <Container key={'space-preview-item' + idx}>
          <Header>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
          </Header>

          {content}
        </Container>
      ))}
    </Wrapper>
  );
};

export default OfferReviewSpace;
