import React from 'react';
import {pluralize} from 'utils/helpers';

import useToggle from 'hooks/useToggle';
import DateUtils from 'utils/dateUtils';
import TruncateText from 'view/components/TruncateText';

import {BookingUnitCardHeader} from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard/BookingUnitCardHeader';
import {BookingUnitCardExtrasRow} from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard/BookingUnitCardExtrasRow';
import {getCurrencySymbol} from 'utils/stringUtils';

import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {
  ExtrasTitle,
  FlexContainer,
  Main,
  MiddleDot,
  UnitTimeAndParticipants,
  UnitTitle,
  Wrapper,
} from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard/NW2BookingUnitCard.styles';
import {IOfferRequestUnitExtra} from './types';
import {ERoomSchema} from 'types/venue';
import {
  EOfferStatus,
  ERequestStatus,
  IOfferUnitExtra,
  IRequestDay,
  IRequestDayItem,
} from 'types/offer';
import {EResourcesCode, EResourcesType} from 'types/dto/IExtras.type';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import SummaryExtrasList from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard/SummaryExtrasList';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';
import {TDay} from 'types/search';
import {IUnitResponse} from 'types/dto/IPublicVenue';

interface IProps {
  requestDay: IRequestDay;
  index: number;
  isPriceHidden?: boolean;
  currencySymbol: string;
  editMode: boolean;
  theme?: EBookingSummaryTheme;
  status?: ERequestStatus | EOfferStatus;
  isOfferRequest?: boolean;
  isOfferPreview?: boolean;
  isAlternative?: boolean;
  getUnitExtras: (
    unitExtras: IOfferUnitExtra[],
    type: EResourcesType,
  ) => IOfferRequestUnitExtra[];
}

export function OfferRequestReviewUnitCard({
  requestDay,
  theme = EBookingSummaryTheme.LIGHT,
  index,
  isPriceHidden,
  status,
  currencySymbol,
  editMode,
  isOfferRequest,
  isOfferPreview,
  isAlternative,
  getUnitExtras,
}: IProps) {
  const [isCollapsed, onToggle] = useToggle(index !== 0);

  const renderExtrasList = (extras: IOfferRequestUnitExtra[]) =>
    extras.map(({name, quantity, totalPrice, currencySymbol}, idx) => (
      <BookingUnitCardExtrasRow
        key={name + idx}
        name={name}
        qty={quantity}
        isPriceHidden={isPriceHidden}
        totalPrice={totalPrice}
        currencySymbol={getCurrencySymbol(currencySymbol)}
      />
    ));

  const bedroomsExtras = requestDay.bedrooms.map(
    ({quantity, code, extraId, price, totalPrice}) => ({
      name: CODE_TO_EXTRA_NAME[code as EResourcesCode],
      totalPrice: totalPrice?.value || price?.value,
      chosenQuantity: quantity,
      extraType: EResourcesType.BEDROOM,
      extraId,
      code,
    }),
  );
  /**
   * todo will be nice to align with BE
   * we have items from request details
   * we have rooms from multibook-details
   */
  const dayRooms = (requestDay.items ||
    (requestDay as unknown as TDay).rooms) as IRequestDayItem[];

  const isMultiDay = dayRooms.length > 1;

  return (
    <Wrapper colorTheme={theme}>
      <BookingUnitCardHeader
        checkInDate={DateUtils.normalizeDateToBackendFormat(requestDay.checkIn)}
        checkOutDate={DateUtils.normalizeDateToBackendFormat(
          requestDay.checkOut,
        )}
        theme={theme}
        isCollapsed={isCollapsed}
        onToggle={onToggle}
        isTimeShowed={!!dayRooms.length}
      />

      <Main isCollapsed={isCollapsed} colorTheme={theme}>
        {dayRooms.map((unit, idx) => {
          const {extras, unitInfo, unitPrice} = unit;

          const equipmentExtras = getUnitExtras(
            extras,
            EResourcesType.EQUIPMENT,
          );

          const altName = `${
            ERoomSchema[
              unit.setupStyle ||
                (unit as unknown as IUnitResponse).requestedSetupStyle
            ]
          } Meeting Room`;

          const unitName =
            unitInfo?.unitName || // group data
            (unit as unknown as IUnitResponse).name || // multibook-details
            altName;

          return (
            <div key={unit.checkIn + idx}>
              <ExtrasTitle>
                Meeting Room {isMultiDay ? idx + 1 : ''}
              </ExtrasTitle>
              <UnitTitle>
                {isAlternative ? (
                  altName
                ) : (
                  <TruncateText text={unitName} hideToggler />
                )}

                {!isPriceHidden && (unitPrice?.value || unitPrice) && (
                  <FlexContainer>
                    {/** @ts-ignore **/}
                    {formatFloatWithOneNumber(unitPrice?.value || unitPrice)}
                    &nbsp;
                    {getCurrencySymbol(unitPrice?.currency) || currencySymbol}
                  </FlexContainer>
                )}
              </UnitTitle>
              {(isOfferRequest ||
                status === ERequestStatus.REQUEST_PENDING ||
                isOfferPreview) && (
                <UnitTimeAndParticipants>
                  {DateUtils.getHoursAndMinutes(unit.checkIn)} -{' '}
                  {DateUtils.getHoursAndMinutes(unit.checkOut)}{' '}
                  <MiddleDot>&#183;</MiddleDot>
                  {pluralize(
                    'people',
                    (unit as unknown as IUnitResponse).requestedCapacity ||
                      unit.participants ||
                      0,
                  )}
                </UnitTimeAndParticipants>
              )}
              {!!equipmentExtras.length && renderExtrasList(equipmentExtras)}
            </div>
          );
        })}

        {!!requestDay?.foodAndBeverage?.length && (
          <>
            <ExtrasTitle>Food & beverage</ExtrasTitle>
            {renderExtrasList(
              getUnitExtras(
                requestDay.foodAndBeverage,
                EResourcesType.FOOD_AND_BEVERAGE,
              ),
            )}
          </>
        )}

        {!!bedroomsExtras?.length && (
          <SummaryExtrasList
            extrasList={bedroomsExtras || []}
            title='Accommodation'
            currencySymbol={currencySymbol}
            editMode={editMode}
            colorTheme={theme}
            isPriceHidden={isPriceHidden || isOfferRequest}
          />
        )}
      </Main>
    </Wrapper>
  );
}
