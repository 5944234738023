import {ERoomType} from './dto/ERoomType.type';
import {EAccommodationType, EVenueType} from './dto/IPublicVenue';
import {ITimeData} from './dto/ISearch.types';
import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from './dto/IExtras.type';
import {
  EEventType,
  ERoomSchemaNames,
  IPointOfInterest,
  TVenueType,
} from './venue';
import {ILocation} from './dto/ILocation.types';

export type TVenuesQuerySearchData = {
  searchString: string;
  latitude: string;
  longitude: string;
  radius?: number;
  page?: number;
  offerPage?: number;
  seatingStyle?: ERoomSchemaNames;

  // TODO: Not sure is it required fields?
  meetingRoomCapacity: number;
  multiRooms: boolean;
  roomType: ERoomType;
  startDate: string;
  endDate: string;
};

export type TSearchVenuesDay = {
  bedrooms: TSearchCriteriaExtra[];
  eventType: EEventType;
  checkIn: string;
  checkOut: string;
  // Fields are not required for Pre-Arrival and Post-Event
  foodAndBeverage?: TSearchCriteriaExtra[];
  maxParticipants?: number;
  roomFilters?: TSearchCriteriaRoom[];
};

export type TSearchVenuesPayload = {
  corporateAccountId: string;
  limit?: number;
  filterDays: TSearchVenuesDay[];
} & TVenuesQuerySearchData;

export type TSearchCriteria = {
  location: boolean;
  cost: number;
  unitTypeDescription: string;
  filterDays?: TSearchVenuesDay[];
  days?: TSearchVenuesDay[];
  seatingStyle?: ERoomSchemaNames;
} & TVenuesQuerySearchData;

export type TSearchVenuePayload = {
  initialTimeData?: ITimeData[];
  meetingRequestData?: TDay[];
  filterDays?: TSearchVenuesDay[];
} & TVenuesQuerySearchData;

export interface ISearchedVenue {
  id?: number;
  accommodationBuildingType: TVenueType;
  accommodationId: number;
  accommodationType: EAccommodationType;
  coverImageLink: string;
  currency: string;
  hasPrice: boolean;
  location: ILocation;
  name: string;
  pointsOfInterest: IPointOfInterest[];
  totalBedrooms: number;
  totalMeetingRooms: number;
}

export interface IVenuesListResponse {
  pageItems: ISearchedVenue[];
  size: number;
  totalFilteredRecords: number;
  filterDays: TSearchVenuesDay[];
}

interface IRoomFilters {
  accommodationId: number;
  checkIn: string;
  checkOut: string;
  priceDay: number;
  priceHour: number;
  priceHalfDay: number;
  setupStyle: ERoomSchemaNames;
  totalPrice: number;
  unitPrice: number;
  extrasPrice: number;
  capacity: number;
  unitId: number;
  position: number;
}

interface IFilterData {
  bedroomsTotalPrice: number;
  checkIn: string;
  checkOut: string;
  filtersTotalPrice: number;
  foodAndBeverageTotalPrice: number;
  roomFilters: IRoomFilters[];
}

export interface ICalculatedVenue {
  accommodationId: number;
  totalPrice: number;
  type: EVenueType;
  filterData: IFilterData[];
}

export type IMergedVenue = ISearchedVenue & ICalculatedVenue;

export type TSearchCriteriaExtra = {
  extraId: number;
  quantity: number;
  extraType?: EResourcesType;
  code?: EResourcesCode;
  accommodationExtraId?: number;
};

export type TSearchCriteriaRoom = {
  unitId?: number;
  checkIn: string;
  checkOut: string;
  capacity: number;
  extras: TSearchCriteriaExtra[];
  setupStyle: ERoomSchemaNames;
  position: number;
};

export type TSearchCriteriaRooms = {
  rooms: TSearchCriteriaRoom[];
};

// multi-search
export type TSelectedOptions = {
  options: IExtrasOption[];
  ids: number[];
  data: Record<string, any>;
};

export interface IData {
  accommodation: number[];
  [key: string]: number | number[];
}

export type TFormObject = Omit<TSelectedOptions, 'data'> & {data: IData};

export type TMeetingRoomItem = {
  timeStart: string;
  timeEnd: string;
  participants: number;
  equipmentData: TSelectedOptions;
  seatsSetup: ERoomSchemaNames;
};

export type TDay = {
  rooms: TMeetingRoomItem[];
  foodBeverageData: TSelectedOptions | null;
  accommodationData: TSelectedOptions | null;
  isPreMeet: boolean;
  isPostMeet: boolean;
  isSectionExpanded: boolean;
  participants: number;

  startDate: string;
  endDate: string;
};

export type TSummaryExtra = {
  roomTimeRange: string;
  participants: number;
  seatsSetup: ERoomSchemaNames;
  equipments: [string, string][];
};

export type TSummaryData = {
  dayTimeRange: string;
  roomsData: TSummaryExtra[];
  isPreMeet: boolean;
  isPostMeet: boolean;
  foodBeverage: [string, string][] | null;
  accommodation: [string, string][] | null;
  isSectionExpanded: boolean;
  dayParticipants: number;
  startDate: string;
  endDate: string;
};

export type TBedroomType = 'single' | 'double';

export type TGroupRoomsItem = {
  isEnabled: boolean;
  qty: number;
  withBreakfast: boolean;
};

export type TGroupRooms = {
  single: TGroupRoomsItem;
  double: TGroupRoomsItem;
};

export interface ISearchTemplateConfig {
  meet: boolean;
  food: boolean;
  room: boolean;
}

export const searchTemplateConfig: Record<string, ISearchTemplateConfig> = {
  [ERoomType.GROUPS]: {meet: false, food: false, room: true},
  [ERoomType.MEETING_ROOM]: {meet: true, food: false, room: false},
};
