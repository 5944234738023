import {IContact} from './IContact.types';
import {IAmenity} from './IAmenity';
import {ILocation} from './ILocation.types';
import {IDay} from './IDay.type';
import {IUnit, TUnitCapacityStyle} from './IUnit.types';
import {
  EBookableWith,
  EPriceType,
  EResourcesCode,
  EResourcesType,
  IExtrasResponse,
} from './IExtras.type';
import {ERoomType} from './ERoomType.type';
import {TImage} from '../app';
import {TSearchCriteriaRoom} from '../search';
import {
  EEventType,
  ERoomSchemaNames,
  EVenueCharacters,
  IPeopleOfInterest,
  IPointOfInterest,
  ISupplier,
  IUnitDimensions,
  IVenueLegalContact,
  TVenueType,
} from 'types/venue';
import {IPreviewExtra} from '../../store/venues/types';

export enum EAccommodationType {
  CORPORATE_OFFICE = 'CORPORATE_OFFICE',
  VENUE = 'VENUE',
}

export interface IExtraResponse {
  id: number;
  name: string;
  extraId?: number;
  code: EResourcesCode;
  extraType: EResourcesType;
  bookableWith: EBookableWith[];
  priceType: EPriceType;
  price: number;
  isEnabled: boolean;
  quantity: number;
  accommodationExtraId: number;
}

export enum EVenueType {
  INSTANT_CORPORATE = 'INSTANT_CORPORATE', // corp office
  INSTANT = 'INSTANT', //  instant + rfp (with total price)
  RTB = 'RTB', // in case at least one of requested room has rtb flag
  RFP = 'RFP', // with all active meetings included (but no total price)
  RFP_WITH_WIP = 'RFP_WITH_WIP', // at least 1 meeting is WIP -> with all default rooms (all request should be with placeholders as alternative request)
  NOT_AVAILABLE = 'NOT_AVAILABLE', // do not return such venues on result list (for public and corporate venues)
}

export interface IUnitResponse {
  unitId: number;
  name: string;
  description: string;
  type: ERoomType;
  roomType: ERoomType;
  unitDimensions: IUnitDimensions;
  floor: number;
  unitPrice: number;
  priceHour: number;
  priceHalfDay: number;
  priceDay: number;
  documents: TImage[];
  unitCapacities: TUnitCapacityStyle[];
  amenities: IAmenity[];
  checkIn: string;
  checkOut: string;
  extras: IExtraResponse[];
  isRtb: boolean;
  requestedCapacity: number;
  requestedSetupStyle: ERoomSchemaNames;
  isOptimalPrice: boolean;
  foodAndBeverage: IExtraResponse[];
  bedrooms: IExtraResponse[];
  totalRoomRentalPrice?: number;
  noRoom?: boolean;
}

export interface IUpdatedUnitResponse extends IUnitResponse {
  accommodationId: number;
  currency: string;
  userId: number | null | undefined;
  totalExtrasPrice?: number;
}

export interface IRoomResponse {
  position: number;
  units: IUnitResponse[];
  key: string;
}

export interface IDayResponse {
  checkIn: string;
  checkOut: string;
  eventType: EEventType;
  maxParticipants: number;
  rooms: IRoomResponse[];
  foodAndBeverage: IExtraResponse[];
  bedrooms: IExtraResponse[];
  packageId: number | null;
  package: {packageId: number; position: number; quantity: number} | null;
}

export interface IUpdatedDayResponse extends IDayResponse {
  extras: IExtraResponse[];
  currency?: string;
  prePostSubTotal?: number;
  accommodationId?: number;
}

export interface IMultiDayPublicVenue {
  accommodationId: number;
  accommodationType: EAccommodationType;
  amenities: IAmenity[];
  currency: string;
  days: IDayResponse[];
  description: string;
  documents: TImage[];
  legalContact: IVenueLegalContact;
  location: ILocation;
  name: string;
  operationalTimes: IDay[];
  pointsOfInterest: IPointOfInterest[] | null;
  totalPrice: number;
  type: EVenueType;
  venueType: TVenueType;
  venueCharacters: EVenueCharacters[];
  userId?: number | null;
  extras?: IPreviewExtra[];
}

export interface IPublicVenue {
  id?: string;
  accommodationId: number;
  amenities: IAmenity[];
  amenitiesIds: number[];
  contact: IContact;
  currency: string;
  description: string;
  documents: any;
  hkey: string;
  legalContact: IVenueLegalContact;
  supplier: ISupplier;
  personOfInterests: IPeopleOfInterest[];
  location: ILocation;
  name: string;
  operationalTimes: IDay[];
  timeZone: string;
  units: IUnit[];
  unitDetails?: IUnit[];
  services: any;
  venueId: number;
  totalBedrooms: number;
  coverImageLink?: string;
  totalPrice: number;
  accommodationType?: EAccommodationType;
  availability?: number;
  responseTime?: number;
  isFullyBooked?: boolean;
  extras: IExtrasResponse[]; // todo from BE we get accommodationExtras & extras, need to fix
  accommodationExtras: IExtrasResponse[];
  accommodationBuildingType: TVenueType;
  accommodationCharacters: EVenueCharacters[];
  pointsOfInterest: IPointOfInterest[] | null;
  allExtrasAvailable?: boolean;
  filterHits?: TSearchCriteriaRoom[];
  isAlternative?: boolean; // todo do we have it?
  isOffer?: boolean;
  userId?: number | null;
}
