import styled from 'styled-components';

import {Link} from 'view/components/Typography';
import NW2Button from 'view/components/NW2Button';

import {
  NW2Gray600Color,
  fontWeightBold,
  fontSizeMd,
  offsetDef,
  offsetXLg,
  xsBp,
  fontSizeLg,
  fontWeightNormal,
  offsetXXSm,
  fontSizeSm,
  lgBp,
  NW2Gray200Color,
  mdBp,
  offsetLg,
  offsetXSm,
  offsetSm,
  NW2Gray500Color,
  offsetXXLg,
  xLgBp,
  borderRadiusXLg,
} from 'constants/styleVars';

export const StyledExtrasList = styled.div`
  & > div {
    margin-bottom: ${offsetXLg};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const Price = styled.span`
  color: ${NW2Gray600Color};
`;

export const Name = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${offsetXXSm};

  @media (min-width: ${xsBp}px) {
    max-width: 250px;
  }

  @media (min-width: ${xLgBp}px) {
    margin-right: ${offsetDef};
    align-items: center;
  }
`;

export const UnitTitle = styled.div<{
  marginBottom?: number;
  marginTop?: number;
}>`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
  margin-bottom: ${({marginBottom}) => `${marginBottom}px`};
  margin-top: ${({marginTop}) => `${marginTop}px`};
`;

export const UnitBox = styled.div`
  display: grid;
  gap: ${offsetXLg} 0;

  @media (min-width: ${lgBp}px) {
    grid-template-columns: 192px 1fr;
    gap: 0 ${offsetXLg};
  }
`;

export const UnitNameLink = styled(Link)`
  display: block;
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetSm};
`;

export const UnitInfo = styled.div`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightBold};

  & > span {
    font-weight: ${fontWeightNormal};
  }

  &:not(&:last-of-type) {
    margin-bottom: ${offsetXXSm};
  }
`;

export const CardWrapper = styled.div`
  padding: ${offsetXLg};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusXLg};
`;

export const FoodBeveragePreviewList = styled.ul`
  padding-left: ${offsetLg};
  margin: ${offsetDef} 0 0 0;

  & > li:not(& > li:last-of-type) {
    margin-bottom: ${offsetXSm};
  }
`;

export const UnitHead = styled.div<{noBottomOffset?: boolean}>`
  margin: 0 0 ${({noBottomOffset}) => (noBottomOffset ? 0 : offsetXLg)};
`;

export const FormActions = styled.div<{
  alignRight?: boolean;
  noTopOffset?: boolean;
}>`
  display: flex;
  gap: ${offsetXLg};
  justify-content: flex-end;
  ${({noTopOffset}) => (noTopOffset ? '' : `padding-top: ${offsetXXLg}`)};

  @media (min-width: ${mdBp}px) {
    justify-content: ${({alignRight}) =>
      alignRight ? 'flex-end' : 'flex-start'};
  }
`;

export const ExtrasWrapper = styled.div`
  margin: 0 0 ${offsetXLg};
`;

export const ParticipantsInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExtrasListWrapper = styled.div`
  margin-top: ${offsetDef};
`;

export const TimeRangeWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 94px 94px;
  gap: 0 ${offsetDef};

  & .react-datepicker__input-container input {
    height: 36px;
    border-color: ${NW2Gray500Color};
  }

  @media (min-width: ${xsBp}px) {
    grid-template-columns: 82px 82px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${offsetSm};
`;

export const SpaceTypeValueWrapper = styled.div`
  display: inline-grid;
  gap: ${offsetXXSm};
  align-items: center;
  grid-template-columns: repeat(2, max-content);
`;

export const SetupStyleButton = styled(NW2Button)`
  border: 1px solid ${NW2Gray500Color};
  border-radius: 2px;
  background: none;
  padding: 6px;

  &:hover {
    color: initial;
    background: none;
    border: 1px solid ${NW2Gray500Color};
  }
`;
