import React from 'react';
import styled from 'styled-components';

import Image from 'view/components/Image';
import OptionsImg from 'img/options_section.webp';
import PartnersImg0 from 'img/options_partners0.svg';
import PartnersImg1 from 'img/options_partners1.svg';
import PartnersImg2 from 'img/options_partners2.svg';
import PartnersImg3 from 'img/options_partners3.svg';

import {
  blackColor,
  fontSizeMd,
  lgBp,
  mdBp,
  NW2FontSizeH1Xs,
  NW2Gray800Color,
  offset120,
  offset80,
  offsetLg,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  smBp,
  xLgBp,
} from 'constants/styleVars';
import {
  NW2ContainerRow,
  NW2ContainerWithOffset,
  NW2Section,
} from 'view/mainLanding/MainLanding.styles';
import {StyledH2, StyledSubH2, StyledSpan} from 'view/components/Typography';
import NW2Button from 'view/components/NW2Button';

const OptionsH2 = styled(StyledH2)`
  @media (min-width: ${mdBp}px) {
    font-size: ${NW2FontSizeH1Xs};
  }
`;

const OptionsButtonWrapper = styled.div`
  margin-top: ${offsetXXLg};
`;

const OptionsImageWrapper = styled.div`
  position: relative;
  min-height: 400px;
  padding-right: ${offset80};
  margin-top: ${offsetXXXLg};
  margin-bottom: ${offsetXXXLg};
  margin-left: -${offsetLg};

  @media (min-width: ${smBp}px) {
    grid-column: span 2;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: ${mdBp}px) {
    grid-column: span 1;
    margin-left: 0;
  }

  @media (min-width: 1600px) {
    min-height: 500px;
  }
`;

const Column2span = styled.div`
  @media (min-width: ${smBp}px) {
    grid-column: span 2;
  }

  @media (min-width: ${mdBp}px) {
    order: 2;
  }
`;

const OptionContainerRow = styled(NW2ContainerRow)`
  grid-template-columns: 1fr;

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: minmax(200px, 2fr) minmax(200px, 1.2fr);
    grid-gap: ${offset120};
  }
`;

const OptionsPartnersWrapper = styled(NW2ContainerRow)`
  grid-column: span 2;
  grid-template-columns: minmax(110px, 1fr) minmax(110px, 1fr);
  gap: 50px 60px;

  @media (min-width: ${smBp}px) {
    grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
  }

  @media (min-width: ${xLgBp}px) {
    grid-column: auto;
  }
`;

const OptionDescription = styled.div`
  width: 100%;

  @media (min-width: ${xLgBp}px) {
    width: 80%;
  }
`;

const DecoratedNW2Section = styled(NW2Section)`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 2%;
    left: 10%;
    right: 0;
    bottom: 42%;
    background: ${NW2Gray800Color};

    @media (min-width: ${smBp}px) {
      top: 0;
      left: 20%;
      bottom: 33%;
    }

    @media (min-width: ${mdBp}px) {
      bottom: 48%;
    }

    @media (min-width: ${lgBp}px) {
      bottom: 50%;
    }

    @media (min-width: ${xLgBp}px) {
      top: 0;
      left: 28%;
      bottom: 42%;
    }

    @media (min-width: 1600px) {
      bottom: 38%;
    }
  }

  > * {
    position: relative;
  }
`;

export function OptionsSection() {
  const clickLearnMore = () => {
    window.open(
      `https://www.hrs.com/enterprise/meetings-as-a-service/`,
      '_blank',
    );
  };

  return (
    <DecoratedNW2Section background={blackColor}>
      <NW2ContainerWithOffset>
        <NW2ContainerRow>
          <Column2span>
            <OptionsH2 white>MEETING-AS-A-SERVICE</OptionsH2>

            <StyledSubH2 white offset={offsetXLg}>
              We transform simple meetings and groups, through a transparent,
              data-enriched platform, to help you achieve operational excellence
              and savings from procure to reclaim.
            </StyledSubH2>

            <OptionsButtonWrapper>
              <NW2Button
                onClick={clickLearnMore}
                inverted
                buttonType='secondary'
              >
                Learn more
              </NW2Button>
            </OptionsButtonWrapper>
          </Column2span>

          <OptionsImageWrapper>
            <Image isExpandToLeft height='100%' src={OptionsImg} />
          </OptionsImageWrapper>
        </NW2ContainerRow>
      </NW2ContainerWithOffset>

      <NW2ContainerWithOffset>
        <OptionContainerRow>
          <div>
            <OptionsH2 white>In good company</OptionsH2>

            <StyledSubH2 white offset={offsetXXXLg}>
              1 in 3 Fortune 500 companies work with us.
            </StyledSubH2>

            <OptionDescription>
              <StyledSpan
                offset={offsetXXXLg}
                fontSize={fontSizeMd}
                NW2Gray50Color
                inline
              >
                HRS is the trusted partner of some of the world’s most
                innovative brands. Contact us to learn more about how we can
                deliver value to your organisation.
              </StyledSpan>
            </OptionDescription>
          </div>
          <OptionsPartnersWrapper>
            <Image width='100%' src={PartnersImg0} />
            <Image width='100%' src={PartnersImg1} />
            <Image width='100%' src={PartnersImg2} />
            <Image width='100%' src={PartnersImg3} />
          </OptionsPartnersWrapper>
        </OptionContainerRow>
      </NW2ContainerWithOffset>
    </DecoratedNW2Section>
  );
}
