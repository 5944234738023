import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

import {IVenuesStore, TRegistrationLinkData} from './types';
import {
  EAccommodationType,
  EVenueType,
  IPublicVenue,
} from 'types/dto/IPublicVenue';
import {IUnit} from 'types/dto/IUnit.types';
import {EMPTY_OBJECT} from 'constants/app';
import {
  ADD_PREVIEW_ERROR_FORM_SECTION,
  CLEAR_VENUE_DETAILS,
  CONFIRM_BOOKING_FAILURE,
  CONFIRM_BOOKING_REQUEST,
  GET_BOOKING_FAILURE,
  GET_VENUE_DETAILS_FAILURE,
  GET_VENUE_DETAILS_REQUEST,
  GET_VENUE_DETAILS_SUCCESS,
  REMOVE_PREVIEW_ERROR_FORM_SECTION,
  SEARCH_VENUES_FAILURE,
  SEARCH_VENUES_REQUEST,
  SEARCH_VENUES_SUCCESS,
  CLEAR_VENUES_RESULT,
  SET_DATEPICKER_DETAILS_SHOWED,
  SET_DETAILS_DATEPICKER_SHOWED,
  SET_DETAILS_NO_OFFERS_SHOWED,
  SET_GOOGLE_MAP_REFETCH,
  SET_GOOGLE_MAP_VISIBLE_RADIUS,
  SET_IS_SEARCH_TEMPLATE_POPUP_VISIBLE,
  SET_IS_GOOGLE_MAP_EXPANDED,
  SET_IS_VENUE_DETAILS_MAP_VISIBLE,
  SET_IS_VENUES_LIST_OPENED,
  SET_MEETING_ROOM_SEATING_PLAN,
  SET_PREVIEW_DATA,
  SET_PREVIEW_TOTAL_AMOUNT,
  GET_REGISTRATION_LINK_DATA_REQUEST,
  GET_REGISTRATION_LINK_DATA_SUCCESS,
  GET_REGISTRATION_LINK_DATA_FAILURE,
  SET_PREVIEW_TOTAL_DRAWER_VISIBLE,
  SET_SPACE_DETAILS_ID,
  SET_VENUE_UNIT_BOOKING_STATISTICS,
  GET_MULTI_VENUE_DETAILS_SUCCESS,
} from './actions';
import {
  INITIAL_BOOKING_PREVIEW,
  INITIAL_VENUE_DETAILS,
} from 'store/venues/constants';
import {findNotAvailableStatus, getHmdRegLink} from 'utils/venueUtils';

import {IMergedVenue} from 'types/search';

export const initialState = {
  loading: false,
  venueGlobalList: [],
  filterDays: [],
  venueDetails: INITIAL_VENUE_DETAILS,
  multiVenueDetails: [],
  bookingPreview: INITIAL_BOOKING_PREVIEW,
  error: null,
  venueUnitBookingStatistics: EMPTY_OBJECT,
  googleMapRefetch: false,
  isGoogleMapExpanded: false,
  spaceDetailsId: null,
  isVenuesListOpened: true,
  isDetailsDatepickerMobileShowed: false,
  isVenueDetailsMapVisible: false,
  isDetailsNoOffersShowed: false,
  isDetailsDatepickerShowed: false,
  isSearchTemplatePopupVisible: false,
  selectedMeetingRoomSeatingPlan: '',
  isMultiRoomsBookingNotAvailable: false,
  registrationLinkData: {
    hkey: '',
    registrationLink: '',
    status: null,
  },
};

// SEARCH VENUE REDUCER
const venuesReducer = (state = initialState, action: any): IVenuesStore => {
  switch (action.type) {
    case SET_IS_VENUES_LIST_OPENED: {
      return {
        ...state,
        isVenuesListOpened: action.payload,
      };
    }

    case SET_DETAILS_DATEPICKER_SHOWED: {
      return {
        ...state,
        isDetailsDatepickerMobileShowed: action.payload,
      };
    }

    case SET_DETAILS_NO_OFFERS_SHOWED: {
      return {
        ...state,
        isDetailsNoOffersShowed: action.payload,
      };
    }

    case SET_DATEPICKER_DETAILS_SHOWED: {
      return {
        ...state,
        isDetailsDatepickerShowed: action.payload,
      };
    }

    case GET_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_VENUE_DETAILS: {
      return {
        ...state,
        venueDetails: INITIAL_VENUE_DETAILS,
        isMultiRoomsBookingNotAvailable: false,
        loading: false,
        error: null,
      };
    }

    case SEARCH_VENUES_REQUEST:
    case CONFIRM_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_VENUE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_VENUE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SEARCH_VENUES_SUCCESS: {
      const availableRecords = action.payload.pageItems.filter(
        ({type}: IMergedVenue) => type !== EVenueType.NOT_AVAILABLE,
      ); // temp solution for filtering NOT_AVAILABLE venues

      const sortOrder = [
        EVenueType.INSTANT_CORPORATE,
        EVenueType.INSTANT,
        EVenueType.RTB,
        EVenueType.RFP,
        EVenueType.RFP_WITH_WIP,
      ];

      return {
        ...state,
        loading: false,
        venueGlobalList: availableRecords.sort(
          (x: IMergedVenue, y: IMergedVenue) =>
            sortOrder.indexOf(x.type) - sortOrder.indexOf(y.type),
        ),
        filterDays: action.payload.filterDays,
      };
    }

    case CLEAR_VENUES_RESULT: {
      return {
        ...state,
        venueGlobalList: [],
      };
    }

    case GET_VENUE_DETAILS_SUCCESS: {
      const {response, isMultiRooms} = action.payload;
      const venueGlobal: IPublicVenue = {
        ...response,
        units: response.units.map((el: IUnit) => ({
          ...el,
          id: el.unitId || el.id,
        })),
        accommodationId: response.id || response.accommodationId,
      };

      const isSomeOfUnitsNotAvailable = venueGlobal.units.some(
        findNotAvailableStatus,
      );

      const isPublicVenue =
        venueGlobal.accommodationType === EAccommodationType.VENUE;

      const isMultiRoomsBookingNotAvailable =
        isMultiRooms &&
        ((!venueGlobal.allExtrasAvailable &&
          isPublicVenue &&
          !venueGlobal.isOffer) ||
          isSomeOfUnitsNotAvailable);

      return {
        ...state,
        venueDetails: venueGlobal,
        isMultiRoomsBookingNotAvailable,
        loading: false,
        error: null,
      };
    }

    case GET_MULTI_VENUE_DETAILS_SUCCESS: {
      return {
        ...state,
        multiVenueDetails: action.payload.response,
        loading: false,
        error: null,
      };
    }

    case SEARCH_VENUES_FAILURE:
    case CONFIRM_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case SET_VENUE_UNIT_BOOKING_STATISTICS: {
      return {
        ...state,
        venueUnitBookingStatistics: action.payload,
      };
    }

    case SET_GOOGLE_MAP_REFETCH: {
      return {
        ...state,
        googleMapRefetch: action.payload,
      };
    }

    case SET_GOOGLE_MAP_VISIBLE_RADIUS: {
      const newState = _cloneDeep(state);
      _set(newState, 'googleMapVisibleRadius', action.payload);
      return newState;
    }

    case SET_SPACE_DETAILS_ID: {
      return {
        ...state,
        spaceDetailsId: action.payload,
      };
    }

    case SET_IS_GOOGLE_MAP_EXPANDED: {
      return {
        ...state,
        isGoogleMapExpanded: action.payload,
      };
    }

    case SET_IS_VENUE_DETAILS_MAP_VISIBLE: {
      return {
        ...state,
        isVenueDetailsMapVisible: action.payload,
      };
    }

    case SET_IS_SEARCH_TEMPLATE_POPUP_VISIBLE: {
      return {
        ...state,
        isSearchTemplatePopupVisible: action.payload,
      };
    }

    case SET_MEETING_ROOM_SEATING_PLAN:
      return {
        ...state,
        selectedMeetingRoomSeatingPlan: action.payload,
      };

    // BOOKING MASK

    case SET_PREVIEW_DATA: {
      return {
        ...state,
        bookingPreview: {...state.bookingPreview, ...action.payload},
      };
    }

    case ADD_PREVIEW_ERROR_FORM_SECTION: {
      const newState = _cloneDeep(state);
      _set(newState, 'bookingPreview.errorFormSections', action.payload);
      return newState;
    }

    case REMOVE_PREVIEW_ERROR_FORM_SECTION: {
      const newState = _cloneDeep(state);
      const errorFormSections = [
        ...state.bookingPreview.errorFormSections,
      ].filter((e) => e !== action.payload);
      _set(newState, 'bookingPreview.errorFormSections', errorFormSections);
      return newState;
    }

    case SET_PREVIEW_TOTAL_DRAWER_VISIBLE: {
      const newState = _cloneDeep(state);
      _set(newState, 'bookingPreview.isTotalDrawerVisible', action.payload);
      return newState;
    }

    case SET_PREVIEW_TOTAL_AMOUNT: {
      const newState = _cloneDeep(state);
      _set(newState, 'bookingPreview.totalAmount', action.payload);
      return newState;
    }

    case GET_REGISTRATION_LINK_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_REGISTRATION_LINK_DATA_SUCCESS: {
      const newState = _cloneDeep(state);

      const link: string = action.payload.registrationLink;
      const shortLink = getHmdRegLink(link);

      const changedPayload: TRegistrationLinkData = {
        ...action.payload,
        registrationLink: shortLink,
      };

      _set(newState, 'registrationLinkData', changedPayload);
      _set(newState, 'loading', false);

      return newState;
    }

    case GET_REGISTRATION_LINK_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default venuesReducer;
