import React, {useMemo} from 'react';

import Image from 'view/components/Image';
import PackagesCard from 'view/venue/NW2BookingPreview/components/Space/components/PackagesCard';

import {formatDate} from 'utils/stringUtils';
import {BookingUnitExtras} from './BookingUnitExtras';
import {EResourcesType} from 'types/dto/IExtras.type';
import {IUnitBookingDetails} from 'types/bookingOrders';
import {findCoverImage, pluralize} from 'utils/helpers';
import {getPackageExtrasDescriptions} from 'view/venue/NW2BookingPreview/helpers';
import {
  filterPreviewExtrasByExtraType,
  formatFloatWithOneNumber,
} from 'utils/venueUtils';
import {ERoomSchema} from 'types/venue';
import {IPackageResponse} from 'types/dto/IPackages.type';
import {
  RentalText,
  Paragraph,
  Span,
  StyledSpan,
  UnitCard,
  UnitName,
  UnitPrice,
  UnitTitle,
} from '../BookingDetails.styles';
import {borderRadiusDef, NW2Gray50Color} from 'constants/styleVars';
import {useAppSelector} from 'store/hooks';

interface IProps {
  currencySymbol?: string;
  index: number;
  unit: IUnitBookingDetails;
  isUnitSingle?: boolean;
}

export function BookingDetailsUnit({
  currencySymbol,
  index,
  unit,
  isUnitSingle,
}: IProps) {
  const packages = useAppSelector(({packages}) => packages.packages);

  const {
    bookedExtras,
    setupStyle,
    checkInDate,
    checkOutDate,
    participants,
    maxParticipants,
    unitPrice,
    unitInfo,
    packageId,
    packagePrice,
  } = unit;

  const checkInTime = formatDate(checkInDate, 'hh:mm', 'default');
  const checkOutTime = formatDate(checkOutDate, 'hh:mm', 'default');

  const customerBookedPackage = packageId
    ? packages.find((item: IPackageResponse) => item.id === packageId)
    : undefined;

  const {unitName, documents} = unitInfo;

  const coverImage = findCoverImage(documents);

  const extras = useMemo(
    () =>
      bookedExtras ? [...bookedExtras].sort((a, b) => b.price - a.price) : [],
    [bookedExtras],
  );

  const equipmentExtras = useMemo(
    () => filterPreviewExtrasByExtraType(extras, EResourcesType.EQUIPMENT),
    [extras],
  );

  const packageFoodAndBeverage = getPackageExtrasDescriptions(
    customerBookedPackage,
    'catering',
  );
  const packageEquipment = getPackageExtrasDescriptions(
    customerBookedPackage,
    'equipment',
  );

  return (
    <>
      <UnitTitle>Meeting room {!isUnitSingle ? index + 1 : ''}</UnitTitle>
      <UnitCard>
        <Image
          src={coverImage.url}
          width='162px'
          height='106px'
          borderRadius={borderRadiusDef}
          data-testid='booking-details-unit-card-image'
        />
        <div>
          <UnitName>{unitName}</UnitName>
          <Paragraph>
            Time:{' '}
            <Span>
              {checkInTime} - {checkOutTime}
            </Span>
          </Paragraph>
          <Paragraph>
            Room setup:{' '}
            <Span>{setupStyle ? ERoomSchema[setupStyle] : '-'}</Span>
          </Paragraph>
          <Paragraph>
            Participants:{' '}
            <Span>
              {pluralize('people', participants || 0)}
              {maxParticipants ? ` (${maxParticipants} max)` : ''}
            </Span>
          </Paragraph>
        </div>
      </UnitCard>
      {customerBookedPackage ? (
        <PackagesCard
          equipment={packageEquipment}
          foodAndBeverage={packageFoodAndBeverage}
          price={packagePrice}
          backgroundColor={NW2Gray50Color}
          participants={participants}
          currencySymbol={currencySymbol}
          isSupplierView
        />
      ) : (
        <UnitPrice>
          <RentalText>Room rental</RentalText>
          <StyledSpan>
            {formatFloatWithOneNumber(unitPrice)} {currencySymbol}
          </StyledSpan>
        </UnitPrice>
      )}
      <>
        <BookingUnitExtras
          data={equipmentExtras}
          currencySymbol={currencySymbol}
          isCustomerBookedPackage={!!customerBookedPackage}
        />
      </>
    </>
  );
}
