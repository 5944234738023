import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import LeftSideItemTitle from 'view/venue/components/LeftSideContainer/LeftSideItemTitle';
import OfferReviewSpace from 'view/venue/NW2BookingPreview/components/Space/OfferReviewSpace';

import DateUtils from 'utils/dateUtils';
import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';
import {offsetXXLg} from 'constants/styleVars';
import {StyledLeftSideItem} from 'view/venue/NW2BookingPreview/components/Space/Space.styles';

type TProps = {
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
  isAlternative?: boolean;
};

export const OfferReviewLeftSideSpaces = ({
  hasPreArrivals,
  hasPostEvents,
  isAlternative,
}: TProps) => {
  const previewUnits: TGroupedUnitsByDay[] = useSelector((state) =>
    _get(state, `venuesReducer.bookingPreview.bookingMaskPreviewUnits`),
  );

  const isMultiDay = previewUnits.length > 1;

  return (
    <>
      {previewUnits.map(
        (
          {checkInDate, checkOutDate, units, foodAndBeverage, bedrooms},
          index,
        ) => {
          const duration = DateUtils.getTotalHours(checkInDate, checkOutDate);

          const title = (
            <LeftSideItemTitle
              title={isMultiDay ? `DAY ${index + 1}` : 'Your room'}
              checkIn={checkInDate}
              checkOut={checkOutDate}
              hasTimeRange={!!units?.length}
            />
          );

          const children = (
            <OfferReviewSpace
              units={units}
              dayIndex={index}
              previewFoodAndBeverage={foodAndBeverage}
              bedrooms={bedrooms}
              duration={duration}
              checkIn={checkInDate}
              isAlternative={isAlternative}
            />
          );

          return (
            <StyledLeftSideItem
              key={index}
              item={{
                title,
                children,
              }}
              isHidden={false}
              paddingTop={
                hasPreArrivals && index === 0
                  ? `${offsetXXLg} !important`
                  : undefined
              }
              paddingBottom={
                !hasPostEvents && index === previewUnits.length - 1
                  ? undefined
                  : `${offsetXXLg} !important`
              }
              isNoBorderTop={hasPreArrivals}
            />
          );
        },
      )}
    </>
  );
};
