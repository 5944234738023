import styled from 'styled-components';

import {
  fontSizeLg,
  fontSizeSm,
  fontWeightBold,
  lgBp,
  lineHeightLg,
  lineHeightMd,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  preLgBd,
  xLgBp,
} from 'constants/styleVars';

export const ExtrasWrapper = styled.div<{isDesktop: boolean}>`
  & + & {
    margin-top: ${({isDesktop}) => (isDesktop ? offsetXLg : offsetXXLg)};
  }

  @media (max-width: ${preLgBd}px) {
    grid-template-columns: auto;
    grid-row-gap: ${offsetDef};

    > div:nth-child(n) {
      grid-column: auto;
    }
  }
`;

export const UnitContainerTitle = styled.div`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetDef};
`;

export const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetDef};

  @media (min-width: ${lgBp}px) {
    display: grid;
    grid-template-columns: 162px auto;
    grid-column-gap: ${offsetXLg};
  }
`;

export const UnitTitle = styled.div`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXSm};
`;

export const UnitText = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
`;

export const TimeBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${xLgBp}px) {
    flex-direction: column;
  }
`;

export const MainTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightLg};
  margin-bottom: ${offsetDef};
`;

export const ExtraListBox = styled.div<{noPaddingTop?: boolean}>`
  padding-top: ${({noPaddingTop}) => !noPaddingTop && offsetDef};
`;
