import React, {useCallback, useState} from 'react';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';

import {EditAccommodation} from './EditAccommodation';

import {FlexContainer} from '../../AddFoodBeverageRequest/AddFoodBeverageRequest.styles';
import {setDefaultExtras} from '../../AddFoodBeverageRequest/helpers';
import {ListContent, ListContentItem} from '../AddAccommodation.styles';
import {TSelectedOptions} from 'types/search';
import {BEDROOM_EXTRAS} from 'constants/bedrooms';
import {TBedroomsCatering, THandleSetBedroomsCatering} from '../types';
import {getCateringStatusByName} from '../helpers';
import {updateFormDataByCatering} from '../../../utils';

type TProps = {
  accommodationData: TSelectedOptions;
  addAccommodation: (formData?: Record<string, any>) => void;
  // meetingRooms: TMeetingRoomItem[];
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  date: string;
  maxParticipants?: number;
  isPreOrPostMeet?: boolean;
  isDayToDelete?: boolean;
  dayIndex?: number;
  deleteDay?: () => void;
  setEditedAccommodationDaysIndex?: React.Dispatch<
    React.SetStateAction<(number | undefined)[]>
  >;
};

export function AccommodationContent({
  accommodationData,
  // meetingRooms,
  addAccommodation,
  bedroomsCatering,
  deleteDay,
  handleSetBedroomsCatering,
  date,
  isDayToDelete,
  maxParticipants = 1,
  isPreOrPostMeet = false,
  setEditedAccommodationDaysIndex,
  dayIndex,
}: TProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const onClickEdit = () => {
    setIsEditMode(true);
  };

  const bedroomsCateringDay = bedroomsCatering[date];
  const singleRoomDay = bedroomsCateringDay?.single;
  const doubleRoomDay = bedroomsCateringDay?.double;
  const isSingleDayNameChanged =
    singleRoomDay && singleRoomDay.preName !== singleRoomDay.name;
  const isDoubleDayNameChanged =
    doubleRoomDay && doubleRoomDay.preName !== doubleRoomDay.name;

  const onSave = useCallback(
    (formData: Record<string, any>) => {
      const updatedFormData = updateFormDataByCatering(
        formData,
        singleRoomDay,
        doubleRoomDay,
      );

      addAccommodation(updatedFormData);

      //case when we save empty accommodation
      if (!formData.accommodation.length && isPreOrPostMeet) {
        if (isDayToDelete && typeof deleteDay === 'function') {
          return deleteDay();
        } else {
          addAccommodation();
        }
      }

      // save changed name on save
      if (isSingleDayNameChanged) {
        handleSetBedroomsCatering({
          roomType: 'single',
          name: singleRoomDay?.preName,
          preName: undefined,
          date,
        });
      }

      if (isDoubleDayNameChanged) {
        handleSetBedroomsCatering({
          roomType: 'double',
          name: doubleRoomDay?.preName,
          preName: undefined,
          date,
        });
      }
      if (setEditedAccommodationDaysIndex) {
        setEditedAccommodationDaysIndex(
          (prevIndexes: (number | undefined)[]) => {
            return [...(prevIndexes || []), dayIndex].filter(
              (index) => typeof index === 'number',
            );
          },
        );
      }

      setIsEditMode(false);
    },
    [
      addAccommodation,
      date,
      dayIndex,
      deleteDay,
      doubleRoomDay,
      handleSetBedroomsCatering,
      isDayToDelete,
      isDoubleDayNameChanged,
      isPreOrPostMeet,
      isSingleDayNameChanged,
      setEditedAccommodationDaysIndex,
      singleRoomDay,
    ],
  );

  const onCancel = useCallback(() => {
    // resets to saved name if it changed & not saved
    if (isSingleDayNameChanged) {
      handleSetBedroomsCatering({
        roomType: 'single',
        preName: singleRoomDay?.name,
        date,
      });
    }

    if (isDoubleDayNameChanged) {
      handleSetBedroomsCatering({
        roomType: 'double',
        preName: doubleRoomDay?.name,
        date,
      });
    }

    setIsEditMode(false);
  }, [
    date,
    doubleRoomDay?.name,
    handleSetBedroomsCatering,
    isDoubleDayNameChanged,
    isSingleDayNameChanged,
    singleRoomDay?.name,
  ]);

  const list = accommodationData ? setDefaultExtras(accommodationData) : null;

  return (
    <div>
      {!isEditMode && list ? (
        <FlexContainer justifyContent='space-between'>
          <ListContent>
            {list.map(({id, name}) => (
              <div key={`bedroom-extra-${id}`}>
                <div>{name}</div>
                <ListContentItem>
                  {getCateringStatusByName(
                    bedroomsCatering[date]?.[
                      name === BEDROOM_EXTRAS.SINGLE ? 'single' : 'double'
                    ].name,
                  )}
                </ListContentItem>
              </div>
            ))}
          </ListContent>

          <NW2Button
            minimized
            buttonType='tertiary'
            size='small'
            icon={<Icon transparent icon='NW2_EDIT' />}
            onClick={onClickEdit}
          />
        </FlexContainer>
      ) : (
        <EditAccommodation
          onSave={onSave}
          onCancel={onCancel}
          participants={maxParticipants}
          bedroomsCatering={bedroomsCatering}
          accommodationData={accommodationData}
          handleSetBedroomsCatering={handleSetBedroomsCatering}
          date={date}
        />
      )}
    </div>
  );
}
