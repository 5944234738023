import {ERoomSchemaNames} from 'types/venue';
import {TImage} from './app';
import {EBookingStatus} from './booking';
import {ERoomType} from './dto/ERoomType.type';
import {
  IOrderDay,
  ISummaryExtra,
  IUnitBooking,
  TAttendee,
} from './dto/IBooking.types';
import {IUnitType} from './dto/IUnitType.type';
import {ICustomerBillingAddress} from './dto/IUser.types';
import {IBookedExtra} from './venue';
import {ECustomerBookingsTabFilter} from './dto/SearchTab.types';
import {ETableSortOrder} from 'types/dto/IBookingType.type';
import {EGroupStatus, EOfferStatus, ERequestStatus} from './offer';

export enum EBookingOrders {
  UPCOMING_ORDERS = 'UPCOMING_ORDERS',
  ORDERS_HISTORY = 'ORDERS_HISTORY',
  RFP_ORDERS = 'RFP_ORDERS',
}

export enum EBookingOrdersSortBy {
  DATE_START = 'DATE_START',
  DATE_END = 'DATE_END',
  GUEST_COMPANY = 'GUEST_COMPANY',
  UNIT_NAME = 'UNIT_NAME',
  BOOKING_ID = 'BOOKING_ID',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  STATUS = 'STATUS',
  SHOW_STATUS = 'SHOW_STATUS',
}

export enum EBookingOrderStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  PASSED = 'PASSED',
  RFP_CANCELLED = 'RFP_CANCELLED',
  RFP_PENDING = 'RFP_PENDING',
  RFP_DECLINED = 'RFP_DECLINED',
  RFP_EXPIRED = 'RFP_EXPIRED',
  ACTIVE = 'ACTIVE',
  RTC_PENDING = 'RTC_PENDING',
  RTC_DECLINED = 'RTC_DECLINED',
  RTC_CANCELLED = 'RTC_CANCELLED',
  RTC_EXPIRED = 'RTC_EXPIRED',
  EDIT_CANCELED_BY_CONFIRM = 'EDIT_CANCELED_BY_CONFIRM', // increase edit mode confirmed by supplier
  EDIT_MODE_CANCELED = 'EDIT_MODE_CANCELED',
}

export enum EBookingChangesStatus {
  RTC_PENDING = 'Request pending',
  RTC_DECLINED = 'Request declined',
  RTC_CANCELLED = 'Request cancelled',
  RTC_EXPIRED = 'Request expired',
}

export enum EOrderShowStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
  PENDING = 'PENDING',
}

export interface ISearchOrder {
  checkInDate: string;
  checkOutDate: string;
  company: string;
  currency: string;
  firstName: string;
  hasCatering: boolean;
  hasEquipment: boolean;
  holdUp: string;
  createdAt: string;
  hourEnd: number;
  hourStart: number;
  id: string | null;
  isMultiDay: boolean;
  lastName: string;
  orderId: string;
  orderNumber: string;
  participants: number;
  status: EBookingOrderStatus | ERequestStatus | EOfferStatus;
  totalPrice: number;
  unitName: string;
  showStatus: EOrderShowStatus | null;
  hasAccommodation: boolean;
  hasMeetingRoom: boolean;
  isRequest?: boolean;
}

export interface IGetBookingOrdersPayload {
  limit: number;
  page?: number;
  offerPage?: number;
  searchTab: EBookingOrders;
  sortBy?: EBookingOrdersSortBy;
  venueId: number;
  roomType: ERoomType[];
}

export interface IGetBookingOrdersResponse {
  totalFilteredRecords: number;
  pageItems: ISearchOrder[];
  size: number;
}

export interface IDeclineSupplierOrderPayload {
  orderId: string;
  reason: string;
  isUnavailable: boolean;
  message?: string;
}

export interface IDeclineOrderChangesPayload {
  orderId: string;
  orderNumber: string;
  declinedReason: string;
  isUnavailable?: boolean;
  additionalMessage?: string;
  approverName: string;
  isDeclineMode?: boolean;
}

export interface IConfirmOrderChangesPayload {
  orderId: string;
  orderNumber: string;
  approverName: string;
  isConfirmMode?: boolean;
}

// Booking details
export type TUnitInfo = {
  documents: TImage[];
  roomType: ERoomType;
  unitName: string;
  unitType: ERoomType;
};

export interface IUnitBookingDetails {
  attendees: TAttendee[];
  bookedExtras: IBookedExtra[];
  bookingId: string;
  bookingNumber: string | null;
  checkInDate: string;
  checkOutDate: string;
  maxParticipants: number;
  participants: number;
  setupStyle: ERoomSchemaNames;
  totalPrice: number;
  unitId: number;
  unitInfo: TUnitInfo;
  unitPrice: number;
  unitQuantity: number;
  unitType: ERoomType | IUnitType;
  packageId: number | null;
  packagePrice?: number;
  unitBookingId?: string;
  accommodations: IBookedExtra[];
  foodAndBeverage: IBookedExtra[];
}

export interface IUnitBookingDayData {
  accommodations: ISummaryExtra[];
  units: IUnitBooking[] | IUnitBookingDetails[];
  daySubTotal: number;
  foodAndBeverage: ISummaryExtra[];
}

export interface IBookingDetails {
  accommodationId: number | null;
  billingAddressDto: ICustomerBillingAddress;
  bookingStatus: EBookingStatus;
  cancellationDateTime: string | null;
  cardSummary: string;
  currency: string;
  customerDeleted: boolean;
  declinedMessage: string | null;
  declinedReason: string | null;
  email: string;
  eventName: string;
  firstName: string;
  holdUp: string;
  isRfp: boolean;
  lastName: string;
  orderId: string;
  orderNumber: string;
  paymentMethod: string;
  paymentsRequest: Record<string, any> | null;
  paymentsResponse: Record<string, any> | null;
  phone: string;
  refundAmount: number | null;
  resolvedAt: string;
  createdAt: string;
  updatedAt: string;
  totalPrice: number;
  unitBookingDtos: IUnitBookingDetails[];
  userId: number;
  userUnregistered: boolean;
  previousOrderVersion: string | null;
  nextOrderVersion: string | null;
  showStatus: EOrderShowStatus | null;
  orderDays: IOrderDay[];
}

export interface IBookingChanges extends IBookingDetails {
  previousOrderId: string;
  nextOrderId?: string;
}

export enum EBookingDetailsSections {
  STATUS = 'Status',
  PRE_ARRIVAL = 'Pre-arrival',
  BOOKING_DETAILS = 'Booking details',
  REQUEST_DETAILS = 'Request details',
  POST_EVENT = 'Post event',
  CONFIRMATION_DETAILS = 'Confirmation details',
  PAYMENT_INFORMATION = 'Payment information',
  BILLING_ADDRESS = 'Billing address',
  TERMS_AND_CONDITIONS = 'Terms & conditions',
}

export enum EBookingDetailsType {
  SINGLE = 'SINGLE',
  SINGLE_REQUEST = 'SINGLE_REQUEST',
  MULTI = 'MULTI',
  MULTI_REQUEST = 'MULTI_REQUEST',

  SINGLE_REQUEST_DECLINED = 'SINGLE_REQUEST_DECLINED',
  SINGLE_REQUEST_CANCELLED_BOOKER = 'SINGLE_REQUEST_CANCELLED_BOOKER',
  SINGLE_REQUEST_EXPIRED = 'SINGLE_REQUEST_EXPIRED',

  MULTI_REQUEST_DECLINED = 'MULTI_REQUEST_DECLINED',
  MULTI_REQUEST_CANCELLED_BOOKER = 'MULTI_REQUEST_CANCELLED_BOOKER',
  MULTI_REQUEST_EXPIRED = 'MULTI_REQUEST_EXPIRED',
}

export interface ICustomerSearchOrder extends ISearchOrder {
  accommodationId: number;
  accommodationName: string;
  accommodationType: string;
  eventName: string;
  hourEnd: number;
  hourStart: number;
  location: string;
  roomType: string;
  unitId: string;
  unitType: string;
  userId: number;
  isOffer?: boolean;
  isRequest?: boolean;
}

export interface ICustomerOverviewOrders {
  pageItems: ICustomerSearchOrder[];
  size: number;
  totalFilteredRecords: number;
}

export interface IGetCustomerOverviewOrdersPayload {
  limit: number;
  page?: number;
  offerPage?: number;
  searchTab: ECustomerBookingsTabFilter;
  sortBy?: EBookingOrdersSortBy;
  sortingDirection?: ETableSortOrder;
  userId: string | number;
}

export interface ITableData {
  orderNumber: string;
  checkInDate: string;
  checkOutDate: string;
  orderType: string;
  status: EBookingOrderStatus | ERequestStatus | EOfferStatus | EGroupStatus;
  totalPrice: {
    totalPrice: number;
    currencySymbol: string;
  };
  accommodationName: string;
  location: string;
  orderId: string;
  venueId: number;
  isMultiDay: boolean;
  expirationDate?: string;
  isRequest?: boolean;
  isOffer?: boolean;
}
