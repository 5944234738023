import React from 'react';
import styled from 'styled-components';

import MeetingRequestSectionTitle from '../MeetingRequestSectionTitle';
import NW2AccordionItem from 'view/components/NW2Accordion/components/NW2AccordionItem';

import {AccommodationContent} from './components/AccommodationContent';

import {TSelectedOptions} from 'types/search';
import {TBedroomsCatering, THandleSetBedroomsCatering} from './types';
import {useInitBedroomsCatering} from '../../hooks/useInitBedroomsCatering';

const StyledNW2AccordionItem = styled(NW2AccordionItem)`
  padding: 0;
`;

type TProps = {
  accommodationData: TSelectedOptions | null;
  // meetingRooms: TMeetingRoomItem[];
  addAccommodation: (formData?: Record<string, any>) => void;
  removeAccommodation: () => void;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  removeBedroomsCatering: (date: string) => void;
  date: string;
  dayIndex: number;
  isAllowed: boolean;
  deleteDay: () => void;
  setEditedAccommodationDaysIndex?: React.Dispatch<
    React.SetStateAction<(number | undefined)[]>
  >;
  dayParticipants?: number;
};

export function AddAccommodationRequest({
  accommodationData,
  // meetingRooms,
  addAccommodation,
  removeAccommodation,
  bedroomsCatering,
  handleSetBedroomsCatering,
  removeBedroomsCatering,
  date,
  setEditedAccommodationDaysIndex,
  dayIndex,
  deleteDay,
  isAllowed,
  dayParticipants,
}: TProps) {
  const isAccommodationEnabled = !!accommodationData;

  useInitBedroomsCatering({
    accommodationData,
    bedroomsCatering,
    date,
    handleSetBedroomsCatering,
  });

  const toggleAccommodation = () => {
    if (isAccommodationEnabled && isAllowed) {
      removeAccommodation();
      removeBedroomsCatering(date);
      deleteDay();
    } else {
      addAccommodation();
    }
  };

  return (
    <StyledNW2AccordionItem
      expandable={isAccommodationEnabled}
      initiallyExpanded
      isDivider={false}
      title={
        <MeetingRequestSectionTitle
          label='Accommodation'
          isSwitchActive={isAccommodationEnabled}
          isSwitchDisabled={!isAllowed}
          onClickSectionHandler={toggleAccommodation}
        />
      }
      content={
        isAccommodationEnabled ? (
          <AccommodationContent
            accommodationData={accommodationData}
            // meetingRooms={meetingRooms}
            addAccommodation={addAccommodation}
            bedroomsCatering={bedroomsCatering}
            handleSetBedroomsCatering={handleSetBedroomsCatering}
            date={date}
            maxParticipants={dayParticipants}
            setEditedAccommodationDaysIndex={setEditedAccommodationDaysIndex}
            dayIndex={dayIndex}
          />
        ) : null
      }
    />
  );
}
