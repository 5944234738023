import React, {useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {DateTime} from 'luxon';

import Icon from 'view/components/Icon';
import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import SeatsSetupGroup from './SeatsSetupGroup/SeatsSetupGroup';
import TimeRangeField from './TimeRangeField';
import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {TMeetingRoomItem} from 'types/search';
import {SubsectionTitle} from '../../MeetingRequestsList.styles';
import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {FlexContainer} from '../../AddFoodBeverageRequest/AddFoodBeverageRequest.styles';
import {getExtraNumericInputVisibility} from 'utils/helpers';
import {offsetDef} from 'constants/styleVars';
import {MAX_EXTRAS_NUMBER, MIN_EXTRAS_NUMBER} from 'constants/app';

type TProps = {
  onSave: (arg0: Record<string, any>) => void;
  onCancel: () => void;
  meetingRoom: TMeetingRoomItem;
  dayParticipants?: number;
};

const EditMeetingRoom = ({onSave, onCancel, meetingRoom}: TProps) => {
  const {equipmentData, timeStart, timeEnd, participants, seatsSetup} =
    meetingRoom;

  const initialValues = useMemo(
    () => ({
      ...equipmentData.data,
      timeRange: {
        timeStart: DateTime.fromISO(timeStart).toJSDate(),
        timeEnd: DateTime.fromISO(timeEnd).toJSDate(),
      },
      participants,
      seatsSetup,
    }),
    [equipmentData.data, participants, seatsSetup, timeEnd, timeStart],
  );

  return (
    <div>
      <Form onSubmit={onSave} initialValues={initialValues}>
        {({handleSubmit, submitting, pristine, values}) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormGroup columnNumber={1} gap={16}>
                <FlexContainer>
                  <TimeRangeField timeRange={values.timeRange} />
                </FlexContainer>
                <FlexContainer
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <FlexContainer alignItems='center'>
                    <Icon icon='PARTICIPANTS' />
                    <span>Room capacity</span>
                  </FlexContainer>
                  <Field name='participants'>
                    {({input}) => (
                      <NW2NumericInput
                        minValue={MIN_EXTRAS_NUMBER}
                        maxValue={MAX_EXTRAS_NUMBER}
                        initialValue={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </FlexContainer>
                <SubsectionTitle>Equipment</SubsectionTitle>
                {meetingRoom.equipmentData.options.map(({id, name}) => {
                  const isNumericInputVisible =
                    values.equipment &&
                    (values.equipment as unknown as number[]).includes(id) &&
                    getExtraNumericInputVisibility(name);

                  if (
                    [
                      'Mouse',
                      'Keyboard',
                      'Printer',
                      'Scanner',
                      'Monitor',
                      'Docking Station',
                    ].includes(name)
                  ) {
                    // hide this items due NMM-448
                    return null;
                  }

                  return (
                    <FlexContainer key={id} justifyContent='space-between'>
                      <NW2FormItemCheckbox
                        id={String(id)}
                        type='checkbox'
                        name='equipment'
                        label={name}
                        value={id}
                      />
                      {isNumericInputVisible && (
                        <Field
                          name={`${id}_`}
                          initialValue={values[`${id}_`] || 1}
                        >
                          {({input}) => (
                            <NW2NumericInput
                              minValue={MIN_EXTRAS_NUMBER}
                              maxValue={MAX_EXTRAS_NUMBER}
                              initialValue={input.value}
                              onChange={input.onChange}
                            />
                          )}
                        </Field>
                      )}
                    </FlexContainer>
                  );
                })}
                <SubsectionTitle>Room setup</SubsectionTitle>
                <SeatsSetupGroup />
              </FormGroup>

              <NMMSubmitSection
                submitLabel='save'
                handleCancel={onCancel}
                disabled={submitting || pristine}
                gap={offsetDef}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default EditMeetingRoom;
