import styled from 'styled-components';

import {
  blackColor,
  borderRadiusDef,
  borderRadiusLg,
  fontSizeXSm,
  fontWeightBold,
  lineHeightSm,
  NW2Gray100Color,
  NW2Gray900Color,
  offsetDef,
  offsetSm,
  offsetLg,
  offsetXXXSm,
} from 'constants/styleVars';

export const Wrapper = styled.div<{
  color: string;
  isIcon: boolean;
  backgroundColor?: string;
  isColorfullBackground?: boolean;
}>`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray900Color};
  display: grid;
  grid-template-columns: ${({isIcon}) => (isIcon ? `${offsetLg} 1fr` : '1fr')};
  grid-column-gap: 10px;
  align-items: start;
  padding: ${offsetSm} ${offsetDef};
  border-radius: ${borderRadiusDef};
  background-color: ${({isColorfullBackground, backgroundColor}) =>
    isColorfullBackground ? backgroundColor : NW2Gray100Color};
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: ${({color}) => color};
    border-radius: ${borderRadiusLg} 0 0 ${borderRadiusLg};
  }
`;

export const Title = styled.div`
  font-weight: ${fontWeightBold};
  color: ${blackColor};
`;

export const IconBox = styled.div<{color: string}>`
  color: ${({color}) => color};
  padding-top: ${offsetXXXSm};

  svg {
    max-width: 100%;
  }
`;
