import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {BedroomUnits} from './components/BedroomUnits';
import {UnitCard} from './components/UnitCard';
import {UnitCardRow} from './components/UnitCardRow';

import {
  ECreateOfferFormFields,
  IPreviewPrices,
  TFormState,
  TPreviewState,
} from '../../types';
import {
  IOfferRequestDay,
  IRequestDay,
  IRequestDayItem,
  IRequestV2,
} from 'types/offer';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {ISpacesDataItemImage} from 'types/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {formatDate, getCurrencySymbol} from 'utils/stringUtils';
import {
  formatFloatWithOneNumber,
  getFilteredExtrasByType,
  getFilteredUnitsByEventType,
} from 'utils/venueUtils';
import {getCoverImage} from 'view/venue/helpers/spaces';
import {roomAmenitiesSet} from 'types/amenities';
import {IReturnUnitsExtrasProps} from '../../hooks/useUnitsExtras';
import {
  Container,
  TotalPrice,
  TotalPriceBlock,
  TotalTaxes,
  TotalTitle,
  TotalWrapper,
  Wrapper,
} from './FormPreview.styles';
import {
  UnitCardContainer,
  UnitCardDay,
  UnitCardDayName,
  UnitCardWrapper,
} from './components/UnitCard.styles';
import {getBedroomsFromState} from '../../helpers';
import {useAppSelector} from 'store/hooks';

interface IProps extends IReturnUnitsExtrasProps, IPreviewPrices {
  formState: TFormState;
  previewState: TPreviewState;
  currency: string;
  hasEquipment: boolean;
  hasCatering: boolean;
  requestDays: IRequestDay[];
  preArrivals: IOfferRequestDay[];
  postEvents: IOfferRequestDay[];
  roomAmenities?: IAmenity[];
  firstMeetingRoomId?: string;
}
export function FormPreview({
  formState,
  previewState,
  requestDays,
  roomAmenities,
  currency,
  hasEquipment,
  hasCatering,
  roomRentalPrices,
  unitsSubtotal,
  totalPrice,
  firstMeetingRoomId = '',
  getBedroomSubtotalPrice,
  preArrivals,
  postEvents,
}: IProps) {
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const requestDetails = useAppSelector(
    ({offers}) => offers.offerDetails,
  ) as IRequestV2;

  const getUnitFormValues = useCallback(
    (unitId: string) => {
      const {
        amenities,
        capacity,
        coverImage,
        description,
        dimensions,
        floor,
        images,
        name,
        updatedCapacities,
      } = formState[unitId] || {};

      const assignRoomType = previewState[unitId]?.assignRoomType;

      const coverImg =
        assignRoomType === 'existed'
          ? getCoverImage((images || []) as ISpacesDataItemImage[])?.url
          : (images as TFileWithPreview[])?.find(
              ({name}) => name === coverImage,
            )?.preview;

      return {
        amenities,
        coverImg,
        description,
        dimensions,
        capacity,
        floor,
        name,
        updatedCapacities,
      };
    },
    [formState, previewState],
  );

  const previewAmenities = useMemo(
    () =>
      (roomAmenities || []).filter(({type}) => roomAmenitiesSet.includes(type)),
    [roomAmenities],
  );

  // getting extras prices from 1st filled room
  const allEquipmentFirstUnitPrices =
    formState[firstMeetingRoomId]?.[ECreateOfferFormFields.PRICES_EQUIPMENT];
  const allCateringFirstUnitPrices =
    formState[firstMeetingRoomId]?.[
      ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE
    ];

  const {isEquipmentInitialised, isCateringInitialised} =
    previewState[firstMeetingRoomId] || {};

  const isExtrasInitialized =
    (hasCatering && isCateringInitialised) ||
    (!hasCatering && hasEquipment && isEquipmentInitialised) ||
    (!hasCatering && !hasEquipment);

  const isSingle = requestDays.length === 1;

  const getSubtotalPricesSum = (unitIds: IRequestDayItem[]) => {
    // sum of all subtotals by day
    const prices = unitIds.map(({id}) => unitsSubtotal[id]);

    if (prices.some((p) => !p)) return 0;

    return prices.reduce((acc, price) => acc + price, 0);
  };

  const requestMeetingDays = getFilteredUnitsByEventType(
    requestDays,
  ) as IRequestDay[];

  /**
   * total price from BE
   * uses in case for onboarded venues
   */
  const totalPriceFromDetails = requestDetails?.totalRequestPrice;

  const getCateringPrices = useCallback(
    (itemId: string) => ({
      ...formState[itemId]?.[ECreateOfferFormFields.PRICES_FOOD_AND_BEVERAGE],
      ...allCateringFirstUnitPrices,
    }),
    [allCateringFirstUnitPrices, formState],
  );

  return (
    <Wrapper id='preview-bar'>
      <Container>
        {preArrivals.map((unit) => (
          <BedroomUnits
            key={unit.id}
            unit={unit}
            title='pre-arrival'
            extrasOption={extrasOption}
            currency={currency}
            isInitialised={
              !!previewState?.[unit.id as string]?.isBedroomsInitialised
            }
            prices={getBedroomsFromState(formState, unit.id as string)}
            getBedroomSubtotalPrice={getBedroomSubtotalPrice}
          />
        ))}

        {requestMeetingDays.map((dayUnit, index) => {
          const dayTitle = `Day ${index + 1}`;

          const cateringExtras = getFilteredExtrasByType(
            dayUnit.foodAndBeverage,
            EResourcesType.FOOD_AND_BEVERAGE,
            extrasOption,
          );

          if (!dayUnit.items?.length) {
            // day without meeting & with accommodation
            const cateringPrices = getCateringPrices(dayUnit.id as string);

            return (
              <BedroomUnits
                key={dayUnit.id}
                unit={dayUnit}
                title={dayTitle}
                extrasOption={extrasOption}
                currency={currency}
                isInitialised={
                  !!previewState?.[dayUnit.id as string]?.isBedroomsInitialised
                }
                prices={getBedroomsFromState(formState, dayUnit.id as string)}
                getBedroomSubtotalPrice={getBedroomSubtotalPrice}
                cateringExtras={cateringExtras}
                cateringPrices={cateringPrices}
                isCateringInitialised={
                  !!previewState?.[dayUnit.id as string]?.isCateringInitialised
                }
              />
            );
          }

          const {checkIn, dayPrice, items, bedrooms} = dayUnit;

          const date = formatDate(checkIn, 'd MMM, YYYY', 'en-US');
          const unitsLen = items?.length || 1;

          // getting bedrooms prices from 1st unit in day
          const firstItemId = items?.[0].id;
          const bedroomsPrices = getBedroomsFromState(formState, firstItemId);
          const isBedroomsInitialised =
            previewState[firstItemId]?.isBedroomsInitialised;

          const bedroomExtras = getFilteredExtrasByType(
            bedrooms,
            EResourcesType.BEDROOM,
            extrasOption,
          );

          return (
            <UnitCardWrapper key={dayTitle}>
              <UnitCardDayName>
                {isSingle ? 'Details' : dayTitle}
              </UnitCardDayName>
              <UnitCardDay>{date}</UnitCardDay>

              <UnitCardContainer>
                {items?.map((unit, idx) => {
                  const unitPreview = previewState[unit.id] || {};

                  const {
                    description,
                    name,
                    capacity,
                    floor,
                    dimensions,
                    amenities,
                    coverImg,
                    updatedCapacities,
                  } = getUnitFormValues(unit.id);

                  const equipmentExtras = getFilteredExtrasByType(
                    unit.extras,
                    EResourcesType.EQUIPMENT,
                    extrasOption,
                  );

                  const allEquipmentPrices = {
                    ...formState[unit.id]?.[
                      ECreateOfferFormFields.PRICES_EQUIPMENT
                    ],
                    ...allEquipmentFirstUnitPrices,
                  };

                  const allCateringPrices = getCateringPrices(unit.id);

                  return (
                    <UnitCard
                      key={unit.id}
                      unit={unit}
                      isEquipmentInitialised={isEquipmentInitialised}
                      isCateringInitialised={isCateringInitialised}
                      isBedroomsInitialised={isBedroomsInitialised}
                      previewState={unitPreview}
                      description={description}
                      unitTitle={
                        isSingle ? 'Meeting room' : `Meeting room ${idx + 1}`
                      }
                      unitName={name}
                      coverImg={coverImg}
                      maxParticipants={
                        capacity?.[unit.setupStyle]?.capacity ||
                        updatedCapacities?.find(
                          ({setupStyle}) => setupStyle === unit.setupStyle,
                        )?.capacity ||
                        0
                      }
                      floor={floor}
                      dimensions={dimensions}
                      roomAmenities={previewAmenities}
                      checkedAmenities={amenities}
                      equipmentPrices={allEquipmentPrices}
                      cateringPrices={allCateringPrices}
                      bedroomsPrices={bedroomsPrices}
                      equipmentExtras={equipmentExtras}
                      cateringExtras={cateringExtras}
                      bedroomExtras={bedroomExtras}
                      currency={currency}
                      roomRentalPrice={roomRentalPrices[unit.id] || 0}
                      isLastUnit={idx === unitsLen - 1}
                      subTotalPrice={
                        (isExtrasInitialized &&
                          (getSubtotalPricesSum(items) || dayPrice?.value)) ||
                        0
                      }
                    />
                  );
                })}
              </UnitCardContainer>
            </UnitCardWrapper>
          );
        })}

        {postEvents.map((unit) => (
          <BedroomUnits
            key={unit.id}
            unit={unit}
            title='post-event'
            extrasOption={extrasOption}
            currency={currency}
            isInitialised={
              !!previewState?.[unit.id as string]?.isBedroomsInitialised
            }
            prices={getBedroomsFromState(formState, unit.id as string)}
            getBedroomSubtotalPrice={getBedroomSubtotalPrice}
          />
        ))}

        <TotalWrapper>
          <TotalTitle>Offer total</TotalTitle>
          <TotalPriceBlock>
            <UnitCardRow
              data={
                ((isExtrasInitialized && totalPrice) ||
                  totalPriceFromDetails) && (
                  <>
                    <TotalPrice>
                      {formatFloatWithOneNumber(
                        totalPrice || totalPriceFromDetails?.value,
                      )}{' '}
                      {totalPriceFromDetails?.currency
                        ? getCurrencySymbol(totalPriceFromDetails.currency)
                        : currency}
                    </TotalPrice>
                    <TotalTaxes>Includes taxes and fees</TotalTaxes>
                  </>
                )
              }
              minWidth='146px'
              minHeight='52px'
            />
          </TotalPriceBlock>
        </TotalWrapper>
      </Container>
    </Wrapper>
  );
}
